define('ember-maskmoney/components/imput-money', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var once = Ember.run.once;
  var observer = Ember.observer;
  var computed = Ember.computed;
  var TextField = Ember.TextField;
  exports.default = TextField.extend({
    prefix: '',
    suffix: '',
    affixesStay: false,
    thousands: ',',
    decimal: '.',
    precision: 2,
    allowZero: true,
    allowNegative: false,
    allowDecimal: true,

    options: computed('prefix', 'suffix', 'affixesStay', 'thousands', 'decimal', 'precision', 'allowZero', 'allowNegative', 'allowDecimal', function () {
      return {
        prefix: this.get('prefix'),
        suffix: this.get('suffix'),
        affixesStay: this.get('affixesStay'),
        thousands: this.get('thousands'),
        decimal: this.get('decimal'),
        precision: this.get('precision'),
        allowZero: this.get('allowZero'),
        allowNegative: this.get('allowNegative'),
        allowDecimal: this.get('allowDecimal')
      };
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      once(function () {
        _this.$().maskMoney(_this.get('options'));
        if (_this.get('allowZero') && _this.get('number') !== undefined || _this.get('number')) {
          _this.notifyPropertyChange('number');
        }
      });
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this.$().maskMoney('destroy');
      this._super.apply(this, arguments);
    },


    setMask: observer('options', function () {
      this.$().maskMoney(this.get('options'));
      this.$().maskMoney('mask');
    }),

    setMaskedValue: observer('number', 'precision', 'decimal', function () {
      var number = parseFloat(this.get('number') || 0).toFixed(this.get('precision'));
      var val = number.toString().replace('.', this.get('decimal'));
      this.$().val(val);
      this.$().maskMoney('mask');
    }),

    setUnmaskedValue: observer('value', 'allowDecimal', function () {
      if (this.get('allowDecimal')) {
        this.set('number', this.$().maskMoney('unmasked')[0]);
      } else {
        this.set('number', this.get('value').replace(/[^0-9]/g, ''));
      }
    })
  });
});