define('ember-inplace-edit/components/ember-inplace-edit', ['exports', 'ember', 'ember-inplace-edit/templates/components/ember-inplace-edit'], function (exports, _ember, _emberInplaceEditTemplatesComponentsEmberInplaceEdit) {

  var $ = _ember['default'].$;

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberInplaceEditTemplatesComponentsEmberInplaceEdit['default'],
    type: 'input',
    disabled: false,
    originalValue: null,
    autoResize: false,

    keyUp: function keyUp(event) {
      if (event.keyCode === 13 && this.get('type') !== "textarea") {
        this.send('doneEditing');
      }

      if (event.keyCode === 27) {
        this.set('value', this.get('originalValue'));
        this.set('isEditing', false);
      }
    },

    mouseEnter: function mouseEnter() {
      $("#" + this.get("elementId") + " .edit").removeClass('hide');
    },

    mouseLeave: function mouseLeave() {
      $("#" + this.get("elementId") + " .edit").addClass('hide');
    },

    height: null,

    focus: _ember['default'].observer('isEditing', function () {
      if (this.get('isEditing')) {
        if (!this.get('autoResize')) {
          var height = this.$().css('height'),
              width = this.$().css('width');
        }

        _ember['default'].run.later(this, function () {
          if (this.get('isEditing')) {
            this.set('originalValue', this.get('value'));

            if (this.get('type') === 'input') {
              this.$('input').focus();
            } else {
              if (this.get('autoResize')) {
                this.$('textarea').focus();
              } else {
                this.$('textarea').css({ height: height, width: width }).focus();
              }
            }

            this.sendAction('on-activated', this.$(), this.get('model'));

            var _this = this;

            this.$(this.get('type')).on('focusout', function () {
              _this.send('doneEditing');
            });
          }
        });
      }
    }),

    isTypeInput: _ember['default'].computed('type', function () {
      return this.get('type') === "input";
    }),

    displayPlaceholder: _ember['default'].computed('text', function () {
      var text = this.get('text');
      text = text && text.replace(/ +/g, ' '); // replace mutiple space with single space
      return text === undefined || text === null || text === "" || text === " ";
    }),

    actions: {
      startEditing: function startEditing() {
        if (this.get('disabled') === false) {
          this.set('isEditing', true);
        }
      },
      doneEditing: function doneEditing() {
        if (this.get('isEditing') === true) {
          this.set('isEditing', false);
          this.sendAction('action', this.get('model'), this.get('value'));
        }
      }
    }
  });
});