define("ember-table/-private/utils/sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mergeSort = mergeSort;
  _exports.sortMultiple = sortMultiple;
  _exports.compareValues = compareValues;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function merge(left, right, comparator) {
    var mergedArray = [];
    var leftIndex = 0;
    var rightIndex = 0;

    while (leftIndex < left.length && rightIndex < right.length) {
      var comparison = comparator(left[leftIndex], right[rightIndex]);

      if (comparison <= 0) {
        mergedArray.push(left[leftIndex]);
        leftIndex++;
      } else {
        mergedArray.push(right[rightIndex]);
        rightIndex++;
      }
    }

    if (leftIndex < left.length) {
      mergedArray.splice.apply(mergedArray, [mergedArray.length, 0].concat(_toConsumableArray(left.slice(leftIndex))));
    }

    if (rightIndex < right.length) {
      mergedArray.splice.apply(mergedArray, [mergedArray.length, 0].concat(_toConsumableArray(right.slice(rightIndex))));
    }

    return mergedArray;
  }
  /**
   * An implementation of the standard merge sort algorithm.
   *
   * This is necessary because we need a stable sorting algorithm that accepts
   * a general comparator. The built in sort function and Ember's sort functions
   * are not stable, and `_.sortBy` doesn't take a general comparator. Ideally
   * lodash would add a `_.sort` function whose API would mimic this function's.
   *
   * @param {Array} array The array to be sorted
   * @param {Comparator} comparator The comparator function to compare elements with.
   * @returns {Array} A sorted array
   */


  function mergeSort(array) {
    var comparator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Ember.compare;

    if (array.length <= 1) {
      return array;
    }

    var middleIndex = Math.floor(array.length / 2);
    var leftArray = mergeSort(array.slice(0, middleIndex), comparator);
    var rightArray = mergeSort(array.slice(middleIndex), comparator);
    return merge(leftArray, rightArray, comparator);
  }

  function sortMultiple(itemA, itemB, sorts, compare, sortEmptyLast) {
    var compareValue;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = sorts[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _step$value = _step.value,
            valuePath = _step$value.valuePath,
            isAscending = _step$value.isAscending;
        var valueA = Ember.get(itemA, valuePath);
        var valueB = Ember.get(itemB, valuePath); // The option only influences the outcome of an ascending sort.

        if (sortEmptyLast) {
          sortEmptyLast = isAscending;
        }

        compareValue = isAscending ? compare(valueA, valueB, sortEmptyLast) : -compare(valueA, valueB, sortEmptyLast);

        if (compareValue !== 0) {
          break;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return compareValue;
  }

  function isExactlyNaN(value) {
    return typeof value === 'number' && isNaN(value);
  }

  function isEmptyString(value) {
    return typeof value === 'string' && value === '';
  }

  function isEmpty(value) {
    return Ember.isNone(value) || isExactlyNaN(value) || isEmptyString(value);
  }

  function orderEmptyValues(itemA, itemB, sortEmptyLast) {
    var aIsEmpty = isEmpty(itemA);
    var bIsEmpty = isEmpty(itemB);
    var less = -1;
    var more = 1;

    if (sortEmptyLast) {
      less = 1;
      more = -1;
    }

    if (aIsEmpty && !bIsEmpty) {
      return less;
    } else if (bIsEmpty && !aIsEmpty) {
      return more;
    } else if (Ember.isNone(itemA) && isExactlyNaN(itemB)) {
      return less;
    } else if (isExactlyNaN(itemA) && Ember.isNone(itemB)) {
      return more;
    } else {
      return 0;
    }
  }

  function compareValues(itemA, itemB, sortEmptyLast) {
    if (isEmpty(itemA) || isEmpty(itemB)) {
      return orderEmptyValues(itemA, itemB, sortEmptyLast);
    }

    return Ember.compare(itemA, itemB);
  }
});