define('ember-bootstrap-datepicker/components/datepicker-support', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var typeOf = Ember.typeOf;
  var isNone = Ember.isNone;
  var observer = Ember.observer;
  var Mixin = Ember.Mixin;
  var on = Ember.on;
  var run = Ember.run;
  exports.default = Mixin.create({
    mustUpdateInput: true,
    value: null,
    // add the observed properties
    minViewMode: undefined,
    format: undefined,
    language: undefined,
    startDate: undefined,
    endDate: undefined,
    customParser: function customParser(value) {
      return value;
    },


    setupBootstrapDatepicker: on('didInsertElement', function () {
      var _this = this;

      this.$().datepicker({
        autoclose: this.get('autoclose'),
        calendarWeeks: this.get('calendarWeeks'),
        clearBtn: this.get('clearBtn'),
        container: this.get('widgetContainer'),
        daysOfWeekDisabled: this.get('daysOfWeekDisabled'),
        defaultViewDate: this.get('defaultViewDate'),
        disableTouchKeyboard: this.get('disableTouchKeyboard'),
        enableOnReadonly: this.get('enableOnReadonly'),
        endDate: this.get('endDate'),
        forceParse: this.get('forceParse'),
        format: this._toString(this.get('format')),
        immediateUpdates: this.get('immediateUpdates'),
        keyboardNavigation: this.get('keyboardNavigation'),
        language: this.get('language') || undefined,
        maxViewMode: this.get('maxViewMode'),
        minViewMode: this.get('minViewMode'),
        multidate: this.get('multidate'),
        multidateSeparator: this.get('multidateSeparator'),
        orientation: this.get('orientation'),
        showOnFocus: this.get('showOnFocus'),
        startDate: this.get('startDate'),
        startView: this.get('startView'),
        todayBtn: this.get('todayBtn'),
        todayHighlight: this.get('todayHighlight'),
        toggleActive: this.get('toggleActive'),
        weekStart: this.get('weekStart'),
        datesDisabled: this.get('datesDisabled')
      }).on('changeDate', function (event) {
        run(function () {
          _this._didChangeDate(event);
        });
      }).on('changeMonth', function (event) {
        // eslint-disable-next-line
        _this.sendAction('changeMonth', event.date);
      }).on('focusout', function (event) {
        // eslint-disable-next-line
        _this.sendAction('focus-out', _this, event);
      }).on('focusin', function (event) {
        // eslint-disable-next-line
        _this.sendAction('focus-in', _this, event);
      }).on('clearDate', function (event) {
        run(function () {
          _this._didChangeDate(event);
        });
      }).on('show', function () {
        // eslint-disable-next-line
        _this.sendAction('show');
      }).on('hide', function () {
        _this._forceParse();
        // eslint-disable-next-line
        _this.sendAction('hide');
      });

      this._updateDatepicker();
    }),

    _forceParse: function _forceParse() {
      // not in support
    },


    teardownBootstrapDatepicker: on('willDestroyElement', function () {
      this.$().datepicker('destroy');
    }),

    didChangeValue: observer('value', function () {
      this._updateDatepicker();
    }),

    _didChangeDate: function _didChangeDate(event) {
      var value = null;

      if (event.date) {
        if (this.get('multidate')) {
          value = this.$().datepicker('getDates');
        } else {
          value = this.$().datepicker('getDate');
        }
      }

      this.set('mustUpdateInput', false);
      this.set('value', value);
      if (event.type === 'clearDate') {
        // eslint-disable-next-line
        this.sendAction('clearDate');
      } else {
        // eslint-disable-next-line
        this.sendAction('changeDate', value);
      }
    },
    didInsertElement: function didInsertElement() {
      this.addObserver('language', function () {
        this.$().datepicker('destroy');
        this.setupBootstrapDatepicker();
      });

      this.addObserver('startDate', function () {
        this.$().datepicker('setStartDate', this.get('startDate'));
        this._updateDatepicker();
      });

      this.addObserver('endDate', function () {
        this.$().datepicker('setEndDate', this.get('endDate'));
        this._updateDatepicker();
      });

      this.addObserver('datesDisabled', function () {
        this.$().datepicker('setDatesDisabled', this.get('datesDisabled'));
        this._updateDatepicker();
      });

      this.addObserver('minViewMode', function () {
        this.$().datepicker('minViewMode', this.get('minViewMode'));
        this.$().data('datepicker')._process_options({ minViewMode: this.get('minViewMode') });
        this._updateDatepicker();
      });

      this.addObserver('format', function () {
        var format = this._toString(this.get('format'));
        this.$().datepicker('format', format);
        this.$().data('datepicker')._process_options({ format: format });
        this._updateDatepicker();
      });
    },
    _updateDatepicker: function _updateDatepicker() {
      var _this2 = this;

      var element = this.$();
      var value = this.get('value');
      var customParser = this.get('customParser');
      var dates = [];

      if (!this.get('mustUpdateInput')) {
        this.set('mustUpdateInput', true);
        return;
      }

      value = customParser(value);

      switch (typeOf(value)) {
        case 'array':
          dates = value;
          break;
        case 'date':
          dates = [value];
          break;
        default:
          dates = [null];
      }
      dates = dates.map(function (date) {
        return isNone(date) ? null : _this2._getDateCloneWithNoTime(date);
      });

      element.datepicker.apply(element, ['update'].concat(_toConsumableArray(dates)));
    },


    // HACK: Have to reset time to 00:00:00 because of the bug in
    //       bootstrap-datepicker
    //       Issue: http://git.io/qH7Hlg
    _getDateCloneWithNoTime: function _getDateCloneWithNoTime(date) {
      var clone = new Date(date.getTime());

      clone.setHours(0);
      clone.setMinutes(0);
      clone.setSeconds(0);
      clone.setMilliseconds(0);

      return clone;
    },


    /**
     * Fix Issue #59
     * _toString Checks and converts the input object and returns  a String if it is required and feasible
     * @param  {Object} obj The object to check
     * @return {Object} The object as a String
     */
    _toString: function _toString(obj) {
      if (obj !== undefined && obj !== 'function') {
        if (typeof obj.toString === 'function') {
          obj = obj.toString();
        } else {
          // No toString() method available - There is nothing else that can be done
          throw new Error("At _toString() (datepicker-support.js) - No toString() method available for the passed object.");
        }
      }
      return obj;
    }
  });
});