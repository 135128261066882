define("ember-bootstrap-changeset-validations/components/bs-form/element", ["exports", "ember-bootstrap/components/bs-form/element"], function (_exports, _element) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _element.default.extend({
    hasValidator: Ember.computed.notEmpty('model.validate'),
    setupValidations: function setupValidations() {
      var key = "model.error.".concat(this.get('property'), ".validation");
      Ember.defineProperty(this, 'errors', Ember.computed("".concat(key, "[]"), function () {
        return Ember.A(this.get(key));
      }));
    }
  });

  _exports.default = _default;
});