define('ember-cli-number-abbr/helpers/number-abbr', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.numberAbbr = numberAbbr;

  function log10(x) {
    if (Math.log10) {
      return Math.log10(x);
    } else {
      return Math.log(x) / Math.LN10;
    }
  }

  function getDecimalPlaces(adjustedNumber, maxSignificantPlaces) {
    var baseLength = Math.ceil(log10(adjustedNumber));
    var decimalPlaces = maxSignificantPlaces - (baseLength > 0 ? baseLength : 0);
    return decimalPlaces > 0 ? decimalPlaces : 0;
  }

  function numberAbbr(_ref, options) {
    var _ref2 = _slicedToArray(_ref, 5);

    var _ref2$0 = _ref2[0];
    var number = _ref2$0 === undefined ? 0 : _ref2$0;
    var _ref2$1 = _ref2[1];
    var delimiter = _ref2$1 === undefined ? '.' : _ref2$1;
    var _ref2$2 = _ref2[2];
    var maxSignificantPlaces = _ref2$2 === undefined ? 3 : _ref2$2;
    var _ref2$3 = _ref2[3];
    var padding = _ref2$3 === undefined ? false : _ref2$3;
    var _ref2$4 = _ref2[4];
    var abbreviations = _ref2$4 === undefined ? null : _ref2$4;
    // jshint ignore:line

    var symbols = abbreviations || _ember['default'].A(['', 'K', 'M', 'B', 'T', 'Qua', 'Qui', 'Sex', 'Sep', 'Oct', 'Non', 'Dec']);

    var results = symbols.map(function (symbol, index) {

      var adjustedNumber = number / Math.pow(10, index * 3);

      var decimalPlaces = getDecimalPlaces(adjustedNumber, maxSignificantPlaces);
      var roundedNumber = adjustedNumber.toFixed(decimalPlaces);

      var strippedNumber = padding ? roundedNumber : roundedNumber.replace(/(\.0+$)/, '');
      strippedNumber = strippedNumber.replace(/\./, delimiter);
      return strippedNumber + symbol;
    });

    var result = _ember['default'].A(results).find(function (result, index) {
      return number.toString().length <= (index + 1) * 3;
    });

    return result;
  }

  exports['default'] = _ember['default'].Helper.helper(numberAbbr);
});