define('ember-cli-nvd3/components/nvd3-chart', ['exports', 'ember', 'd3', 'nv'], function (exports, _ember, _d3, _nv) {
  var _slice = Array.prototype.slice;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var isNone = _ember['default'].isNone;
  var run = _ember['default'].run;
  var on = _ember['default'].on;
  var $ = _ember['default'].$;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['nvd3-chart'],
    type: 'lineChart',
    datum: [],
    options: {},
    dispatchEvents: {},

    _container: null,
    _chart: null,

    // Actions
    beforeSetup: _ember['default'].K,
    afterSetup: _ember['default'].K,

    eventContext: computed(function () {
      return this.get('targetObject') || this;
    }),

    reDraw: on('didInsertElement', observer('datum', 'datum.[]', function () {
      run.scheduleOnce('render', this, this.drawChart);
    })),

    drawChart: function drawChart() {
      var _this = this;

      _nv['default'].addGraph(function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        var chart = undefined;
        var chartType = _this.get('type');
        var selector = '#' + _this.get('elementId');
        var context = _this.get('eventContext');
        var svgContainer = undefined;

        if (isNone(_nv['default'].models[chartType])) {
          throw new TypeError('Could not find chart of type ' + chartType);
        }

        $(selector).html('');

        svgContainer = _d3['default'].select(selector).append('svg');
        chart = _nv['default'].models[chartType]();

        _this.set('_container', svgContainer);
        _this.set('_chart', chart);

        run(function () {
          return _this.get('beforeSetup').call(context, svgContainer, chart);
        });

        _this.evaluateOptions(chart);

        // Dispatched events setup
        _this.setupEvents(chart);

        svgContainer.datum(_this.get('datum'));
        svgContainer.call(chart);

        run(function () {
          return _this.get('afterSetup').call(context, svgContainer, chart);
        });

        // Handle window resize
        _this.set('_windowResizeHandler', _nv['default'].utils.windowResize(chart.update));

        return chart;
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var chart = this.get('_chart');
      var resizeHandler = this.get('_windowResizeHandler');

      if (resizeHandler && resizeHandler.clear) {
        resizeHandler.clear();
      }

      // Remove tooltips
      if (chart.tooltip) {
        chart.tooltip.hideDelay(0); // Set the delay to 0 so tooltips will be instantly removed
        chart.tooltip.hidden(true);
      }
    },

    evaluateOptions: function evaluateOptions(chart) {
      var options = this.get('options');
      var type = this.get('type');
      Object.keys(options).forEach(function (key) {
        if (key === 'chart' && chart.options) {
          chart.options(options[key]);
        } else if (chart[key] && chart[key].options) {
          chart[key].options(options[key]);
        } else {
          _ember['default'].Logger.warn(key + ' is not a valid property for chart of type \'' + type + '\'');
        }
      });
    },

    setupEvents: function setupEvents(chart) {
      var events = this.get('dispatchEvents');
      var context = this.get('eventContext');
      var container = this.get('_container');

      Object.keys(events).forEach(function (key) {
        var eventsObj = events[key];
        Object.keys(eventsObj).forEach(function (e) {
          var dispatchingObj = key === 'chart' ? chart : chart[key];
          if (dispatchingObj && dispatchingObj.dispatch) {
            dispatchingObj.dispatch.on(e, function () {
              var _eventsObj$e;

              (_eventsObj$e = eventsObj[e]).call.apply(_eventsObj$e, [context, container, chart].concat(_slice.call(arguments)));
            });
          }
        });
      });
    }
  });
});