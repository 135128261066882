define('ember-responsive/initializers/responsive', ['exports'], function (exports) {
  exports.initialize = initialize;
  /**
   * Ember responsive initializer,
   *
   * Injects the media service in all controllers route components and views
   */

  function initialize() {
    var application = arguments[1] || arguments[0];
    application.inject('controller', 'media', 'service:media');
    application.inject('component', 'media', 'service:media');
    application.inject('route', 'media', 'service:media');
    application.inject('view', 'media', 'service:media');
  }

  exports['default'] = {
    name: 'responsive',
    initialize: initialize
  };
});