define('ember-bootstrap-power-select/components/bs-form/element/control/power-select-multiple', ['exports', 'ember-bootstrap/components/bs-form/element/control', 'ember-bootstrap-power-select/templates/components/bs-form/element/control/power-select-multiple'], function (exports, _control, _powerSelectMultiple) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _control.default.extend({
    layout: _powerSelectMultiple.default,
    tagName: '',

    /**
     * @property powerSelectComponent
     * @type {String}
     */
    powerSelectComponent: 'power-select-multiple',

    /**
     * @property powerSelectBlocklessComponent
     * @type {String}
     */
    powerSelectBlocklessComponent: 'power-select-multiple-blockless'
  });
});