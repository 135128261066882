define('ember-rapid-forms/components/html-text', ['exports', 'ember', 'ember-rapid-forms/templates/components/html-text'], function (exports, _ember, _emberRapidFormsTemplatesComponentsHtmlText) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberRapidFormsTemplatesComponentsHtmlText['default'],
    didReceiveAttrs: function didReceiveAttrs() /*attrs*/{
      this._super.apply(this, arguments);
      // set it to the correct value of the selection
      this.selectedValue = _ember['default'].computed('mainComponent.model.' + this.get('mainComponent.property'), function () {
        return this.get('mainComponent.model.' + this.get('mainComponent.property'));
      });
    },
    actions: {
      change: function change() {
        var selectedEl = this.$('textarea')[0];
        var value = selectedEl.value;
        this.set('mainComponent.model.' + this.get('mainComponent.property'), value);
        var changeAction = this.get('action');
        if (changeAction) {
          changeAction(value);
        } else {
          // TODO make deprecate here so everyone switches to new action syntax
        }
      },
      input: function input() {
        // input is always called when input is altert
        // except in IE9 where when cutting or removing things it doesn't get fired
        // https://developer.mozilla.org/en-US/docs/Web/Events/input#Browser_compatibility
        var selectedEl = this.$('textarea')[0];
        var value = selectedEl.value;
        this.set('mainComponent.model.' + this.get('mainComponent.property'), value);
        var changeAction = this.get('action');
        if (changeAction) {
          changeAction(value);
        } else {
          // TODO make deprecate here so everyone switches to new action syntax
        }
      }
    }
  });
});