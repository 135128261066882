define('ember-intl/helpers/intl-get', ['exports', 'ember-intl/helpers/l'], function (exports, _l) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Helper = Ember.Helper;
  var get = Ember.get;
  var deprecate = Ember.deprecate;


  var IntlGetHelper = Helper.extend({
    intl: service(),

    init: function init() {
      this._super();

      deprecate('[ember-int] intl-get is deprecated, use {{t \'translation.key\'}}', false, {
        id: 'ember-intl-t-helper',
        until: '3.0.0'
      });

      get(this, 'intl').on('localeChanged', this, this.recompute);
    },
    compute: function compute(params) {
      var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return new _l.LiteralWrapper(get(this, 'intl').lookup(params[0], hash.locale));
    },
    willDestroy: function willDestroy() {
      this._super();

      get(this, 'intl').off('localeChanged', this, this.recompute);
    }
  });

  exports.default = IntlGetHelper;
});