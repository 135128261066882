define("ember-inputmask/components/date-input", ["exports", "ember-inputmask/components/input-mask"], function (_exports, _inputMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * `{{date-input}}` component.
   *
   * Displays an input that masks dates.
   *
   * @class DateInput
   */
  var _default = _inputMask.default.extend({
    oldComponent: '{{date-input}}',
    newComponent: '{{one-way-date-mask}}',
    mask: 'datetime',
    options: Ember.computed(function () {
      return {
        inputFormat: 'dd/mm/yyyy',
        outputFormat: 'ddmmyyyy'
      };
    })
  });

  _exports.default = _default;
});