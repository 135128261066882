define('ember-drag-sort/components/drag-sort-item', ['exports', 'ember-macro-helpers/computed', 'ember-awesome-macros/and', 'ember-awesome-macros/not', 'ember-awesome-macros/eq', 'ember-awesome-macros/subtract', 'ember-drag-sort/templates/components/drag-sort-item'], function (exports, _computed, _and, _not, _eq, _subtract, _dragSortItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var reads = Ember.computed.reads;
  var on = Ember.on;
  var observer = Ember.observer;
  var next = Ember.run.next;
  var $ = Ember.$;
  exports.default = Component.extend({

    // ----- Arguments -----
    item: undefined,
    index: undefined,
    items: undefined,
    group: undefined,
    childTagName: 'div',
    draggingEnabled: undefined,
    handle: null,

    dragEndAction: undefined,
    determineForeignPositionAction: undefined,

    // ----- Services -----
    dragSort: service(),

    // ----- Overridden properties -----
    layout: _dragSortItem.default,
    classNameBindings: [':dragSortItem', 'isDragged2:-isDragged', 'isDraggingOver:-isDraggingOver', 'shouldShowPlaceholderAbove2:-placeholderAbove', 'shouldShowPlaceholderBelow2:-placeholderBelow'],

    attributeBindings: ['draggable'],

    // ----- Static properties -----
    isDragged2: false,
    originalHeight: null,

    shouldShowPlaceholderAbove2: undefined,
    shouldShowPlaceholderBelow2: undefined,

    // ----- Aliases -----
    isDraggingUp: reads('dragSort.isDraggingUp'),
    sourceList: reads('dragSort.sourceList'),
    sourceIndex: reads('dragSort.sourceIndex'),
    targetIndex: reads('dragSort.targetIndex'),
    targetList: reads('dragSort.targetList'),

    // ----- Computed properties -----
    draggable: (0, _computed.default)('draggingEnabled', 'handle', function (draggingEnabled, handle) {
      return !handle && draggingEnabled ? true : null;
    }),

    isDragged: (0, _and.default)('dragSort.isDragging', (0, _eq.default)('items', 'sourceList'), (0, _eq.default)('index', 'sourceIndex')),

    isDraggingOver: (0, _and.default)('dragSort.isDragging', (0, _eq.default)('items', 'targetList'), (0, _eq.default)('index', 'targetIndex'), (0, _not.default)('isDragged')),

    $handle: (0, _computed.default)('handle', function (handleClass) {
      return this.$(handleClass);
    }),

    isLast: (0, _eq.default)('index', (0, _subtract.default)('items.length', 1)),
    shouldShowPlaceholderAbove: (0, _and.default)('isDraggingOver', 'isDraggingUp'),
    shouldShowPlaceholderBelow: (0, _and.default)('isDraggingOver', (0, _not.default)('isDraggingUp')),

    // ----- Overridden methods -----
    dragStart: function dragStart(event) {
      // Ignore irrelevant drags
      if (!this.get('draggingEnabled')) return;

      if (!this.isHandleUsed(event.target)) {
        event.preventDefault();
        return;
      }

      event.stopPropagation();

      // Required for Firefox. http://stackoverflow.com/a/32592759/901944
      if (event.dataTransfer) {
        if (event.dataTransfer.setData) event.dataTransfer.setData('text', 'anything');
        if (event.dataTransfer.setDragImage) event.dataTransfer.setDragImage(this.$().get(0), 0, 0);
      }

      this.startDragging(event);
    },
    dragEnd: function dragEnd(event) {
      // Ignore irrelevant drags
      if (!this.get('dragSort.isDragging')) return;

      event.stopPropagation();

      this.endDragging(event);
    },
    dragOver: function dragOver(event) {
      // Ignore irrelevant drags
      if (!this.get('dragSort.isDragging') || this.get('determineForeignPositionAction')) return;

      var group = this.get('group');
      var activeGroup = this.get('dragSort.group');

      if (group !== activeGroup) return;

      event.stopPropagation();

      var pageY = event.originalEvent ? event.originalEvent.pageY : event.pageY;

      this.draggingOver({ pageY: pageY });
    },
    dragEnter: function dragEnter(event) {
      if (!this.get('dragSort.isDragging')) return;
      // Without this, dragOver would not fire in IE11. http://mereskin.github.io/dnd/
      event.preventDefault();
    },


    // ----- Custom methods -----
    startDragging: function startDragging() {
      this.collapse();

      var item = this.get('item');
      var index = this.get('index');
      var items = this.get('items');
      var group = this.get('group');
      var dragSort = this.get('dragSort');

      dragSort.startDragging({ item: item, index: index, items: items, group: group });
    },
    endDragging: function endDragging() {
      this.restore();

      var action = this.get('dragEndAction');
      var dragSort = this.get('dragSort');

      dragSort.endDragging({ action: action });
    },
    draggingOver: function draggingOver(_ref) {
      var pageY = _ref.pageY;

      var group = this.get('group');
      var index = this.get('index');
      var items = this.get('items');
      var top = this.$().offset().top;
      var height = this.$().outerHeight();
      var isDraggingUp = pageY - top < height / 2;

      this.get('dragSort').draggingOver({ group: group, index: index, items: items, isDraggingUp: isDraggingUp });
    },
    collapse: function collapse() {
      var _this = this;

      // The delay is necessary for HTML classes to update with a delay.
      // Otherwise, dragging is finished immediately.
      next(function () {
        if (_this.get('isDestroying') || _this.get('isDestroyed')) return;
        _this.set('isDragged2', true);
      });
    },
    restore: function restore() {
      var _this2 = this;

      // The delay is necessary for HTML class to update with a delay.
      // Otherwise, dragging is finished immediately.
      next(function () {
        if (_this2.get('isDestroying') || _this2.get('isDestroyed')) return;
        _this2.set('isDragged2', false);
      });
    },
    isHandleUsed: function isHandleUsed(target) {
      var handle = this.get('handle');

      if (!handle) return true;

      var $target = $(target);

      if ($target.is(handle)) return true;

      return $target.parentsUntil(this.$()).toArray().some(function (el) {
        return $(el).is(handle);
      });
    },


    // ----- Observers -----
    consumePlaceholderCPs: on('didInsertElement', function () {
      this.getProperties('shouldShowPlaceholderAbove', 'shouldShowPlaceholderBelow');
    }),

    setPlaceholderAbove: observer('shouldShowPlaceholderAbove', function () {
      var _this3 = this;

      // The delay is necessary for HTML class to update with a delay.
      // Otherwise, dragging is finished immediately.
      next(function () {
        if (_this3.get('isDestroying') || _this3.get('isDestroyed')) return;
        _this3.set('shouldShowPlaceholderAbove2', _this3.get('shouldShowPlaceholderAbove'));
      });
    }),

    setPlaceholderBelow: observer('shouldShowPlaceholderBelow', function () {
      var _this4 = this;

      // The delay is necessary for HTML class to update with a delay.
      // Otherwise, dragging is finished immediately.
      next(function () {
        if (_this4.get('isDestroying') || _this4.get('isDestroyed')) return;
        _this4.set('shouldShowPlaceholderBelow2', _this4.get('shouldShowPlaceholderBelow'));
      });
    })
  });
});