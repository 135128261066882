define('ember-drag-sort/components/drag-sort-list', ['exports', 'ember-awesome-macros/and', 'ember-awesome-macros/or', 'ember-awesome-macros/eq', 'ember-drag-sort/templates/components/drag-sort-list'], function (exports, _and, _or, _eq, _dragSortList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var reads = Ember.computed.reads;
  var get = Ember.get;
  var observer = Ember.observer;
  var next = Ember.run.next;
  exports.default = Component.extend({

    // ----- Arguments -----
    items: undefined,
    group: undefined,
    draggingEnabled: true,
    childClass: '',
    childTagName: 'div',
    handle: null,

    dragEndAction: undefined,
    determineForeignPositionAction: undefined,

    // ----- Services -----
    dragSort: service(),

    // ----- Overridden properties -----
    layout: _dragSortList.default,
    classNameBindings: [':dragSortList', 'draggingEnabled:-draggingEnabled', 'isDragging:-isDragging', 'isDraggingOver:-isDraggingOver', 'isExpanded2:-isExpanded', 'isEmpty:-isEmpty'],

    // ----- Static properties -----


    // ----- Aliases -----
    sourceList: reads('dragSort.sourceList'),
    targetList: reads('dragSort.targetList'),
    sourceIndex: reads('dragSort.sourceIndex'),
    draggedItem: reads('dragSort.draggedItem'),
    lastDragEnteredList: reads('dragSort.lastDragEnteredList'),

    // ----- Computed properties -----
    isDragging: (0, _and.default)('dragSort.isDragging', (0, _eq.default)('group', 'dragSort.group')),

    isDraggingOver: (0, _and.default)('isDragging', (0, _eq.default)('items', 'targetList')),

    isExpanded: (0, _and.default)('isDragging', (0, _or.default)('isEmpty', 'isOnlyElementDragged')),

    isExpanded2: reads('isExpanded'),

    isEmpty: (0, _eq.default)('items.length', 0),

    isOnlyElementDragged: (0, _and.default)((0, _eq.default)('items.length', 1), (0, _eq.default)('items', 'sourceList'), (0, _eq.default)('sourceIndex', 0)),

    // ----- Overridden methods -----
    dragEnter: function dragEnter(event) {
      // Ignore irrelevant drags
      if (!this.get('dragSort.isDragging')) return;

      // Ignore irrelevant groups
      var group = this.get('group');
      var activeGroup = this.get('dragSort.group');
      if (group !== activeGroup) return;

      event.stopPropagation();

      // Ignore duplicate events (explanation: https://github.com/lolmaus/jquery.dragbetter#what-this-is-all-about )
      var items = this.get('items');
      var lastDragEnteredList = this.get('lastDragEnteredList');
      if (items === lastDragEnteredList) return;

      this.dragEntering();

      if (this.get('determineForeignPositionAction')) {
        this.forceDraggingOver();
      }
    },


    // ----- Custom methods -----
    dragEntering: function dragEntering() {
      var group = this.get('group');
      var items = this.get('items');
      var dragSort = this.get('dragSort');

      dragSort.dragEntering({ group: group, items: items });
    },
    forceDraggingOver: function forceDraggingOver() {
      var determineForeignPositionAction = this.get('determineForeignPositionAction');

      var group = this.get('group');
      var items = this.get('items');
      var itemsLength = get(items, 'length');
      var draggedItem = this.get('draggedItem');
      var sourceList = this.get('sourceList');

      var isDraggingUp = true;

      var index = items === sourceList ? items.indexOf(draggedItem) + 1 : determineForeignPositionAction({ draggedItem: draggedItem, items: items });

      if (index >= itemsLength) {
        index = itemsLength - 1;
        isDraggingUp = false;
      }

      this.get('dragSort').draggingOver({ group: group, index: index, items: items, isDraggingUp: isDraggingUp });
    },


    // ----- Observers -----
    setIsExpanded2: observer('isExpanded', function () {
      var _this = this;

      // The delay is necessary for HTML class to update with a delay.
      // Otherwise, dragging is finished immediately.
      next(function () {
        if (_this.get('isDestroying') || _this.get('isDestroyed')) return;

        _this.set('isExpanded2', _this.get('isExpanded'));
      });
    })
  });
});