define("ember-nvd3-shim/utils/nv", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = nv;
  function nv() {
    return true;
  }
});