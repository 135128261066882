define('ember-rapid-forms/mixins/in-form', ['exports', 'ember'], function (exports, _ember) {

  /*
  Find the form of the component that merges this mixin
   */
  exports['default'] = _ember['default'].Mixin.create({
    form: _ember['default'].computed('parentView', {
      get: function get() {
        var parentView;
        parentView = this.get('parentView');
        while (parentView) {
          if (parentView.get('tagName') === 'form') {
            return parentView;
          }
          parentView = parentView.get('parentView');
        }
        return _ember['default'].assert(false, 'Cannot find form');
      }
    }),
    model: _ember['default'].computed('form', 'form.model', {
      get: function get() {
        return this.get('form.model');
      }
    })
  });
});