define('ember-bootstrap-datepicker/components/bootstrap-datepicker-inline', ['exports', 'ember-bootstrap-datepicker/components/datepicker-support'], function (exports, _datepickerSupport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_datepickerSupport.default, {
    tagName: 'div'
  });
});