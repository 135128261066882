define('ember-intl/formatters/format-relative', ['exports', 'intl-format-cache', 'intl-relativeformat', 'ember-intl/formatters/-base'], function (exports, _intlFormatCache, _intlRelativeformat, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  function assertIsDate(date, errMsg) {
    (true && !(isFinite(date)) && Ember.assert(errMsg, isFinite(date)));
  }

  var FormatRelative = _base.default.extend({
    formatter: computed({
      get: function get() {
        return (0, _intlFormatCache.default)(_intlRelativeformat.default);
      }
    }).readOnly(),

    format: function format(value, options, ctx) {
      var dateValue = new Date(value);
      var formatOptions = void 0;

      /* TODO: remove assertion in 3.0, new Intl.DateTimeFormat().format() accepts no arguments */
      assertIsDate(dateValue, 'A date or timestamp must be provided to format-relative');

      if (options && typeof options.now !== 'undefined') {
        formatOptions = {
          now: options.now
        };
      }

      return this._format(dateValue, this.filterSupporedOptions(options), formatOptions, ctx);
    }
  });

  FormatRelative.reopenClass({
    formatType: 'relative',
    supportedOptions: ['style', 'units']
  });

  exports.default = FormatRelative;
});