define('ember-cli-string-helpers/helpers/underscore', ['exports', 'ember-cli-string-helpers/-private/create-string-helper'], function (exports, _createStringHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.underscore = undefined;
  var helper = Ember.Helper.helper;
  var _underscore = Ember.String.underscore;
  var underscore = exports.underscore = (0, _createStringHelper.default)(_underscore);
  exports.default = helper(underscore);
});