define('ember-models-table/components/models-table', ['exports', 'ember-models-table/utils/better-compare', 'ember-models-table/themes/bootstrap3', 'ember-models-table/templates/components/models-table', 'ember-models-table/utils/column'], function (exports, _betterCompare, _bootstrap, _modelsTable, _column) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var emberAssign = Ember.assign;
  var on = Ember.on;
  var typeOf = Ember.typeOf;
  var compare = Ember.compare;
  var isBlank = Ember.isBlank;
  var isNone = Ember.isNone;
  var isPresent = Ember.isPresent;
  var run = Ember.run;
  var Component = Ember.Component;
  var O = Ember.Object;
  var observer = Ember.observer;
  var computed = Ember.computed;
  var getProperties = Ember.getProperties;
  var setProperties = Ember.setProperties;
  var getWithDefault = Ember.getWithDefault;
  var set = Ember.set;
  var get = Ember.get;
  var capitalize = Ember.String.capitalize;
  var dasherize = Ember.String.dasherize;
  var jQ = Ember.$;
  var isArray = Ember.isArray;
  var A = Ember.A;
  var keys = Object.keys;


  var NOT_SORTED = -1;

  var defaultMessages = {
    searchLabel: 'Search:',
    searchPlaceholder: '',
    groupByLabel: 'Group by:',
    'columns-title': 'Columns',
    'columns-showAll': 'Show All',
    'columns-hideAll': 'Hide All',
    'columns-restoreDefaults': 'Restore Defaults',
    tableSummary: 'Show %@ - %@ of %@',
    allColumnsAreHidden: 'All columns are hidden. Use <strong>columns</strong>-dropdown to show some of them',
    noDataToShow: 'No records to show'
  };

  var assign = emberAssign || Object.assign;

  /**
   * @ignore
   * @param {ModelsTableColumn} column
   * @returns {boolean}
   */
  function isSortedByDefault(column) {
    return column.sortPrecedence > NOT_SORTED;
  }

  /**
   * Default filter-function used in the filter by columns
   *
   * @param {string} cellValue value in the table cell
   * @param {string} filterString needed substring
   * @returns {boolean}
   * @ignore
   */
  function defaultFilter(cellValue, filterString) {
    return -1 !== cellValue.indexOf(filterString);
  }

  /**
   * Convert some string to the human readable one
   *
   * @param {string} name value to convert
   * @returns {string}
   * @ignore
   */
  function propertyNameToTitle(name) {
    return capitalize(dasherize(name).replace(/-/g, ' '));
  }

  /**
   * @ignore
   * @param {string} option
   * @returns {{value: *, label: *}}
   */
  function optionStrToObj(option) {
    return { value: option, label: option };
  }

  /**
   * Updates <code>filterOptions</code> for column which use <code>filterWithSelect</code>
   * and don't have <code>predefinedFilterOptions</code>
   * <code>filterOptions</code> are calculated like <code>data.mapBy(column.propertyName).uniq()</code>,
   * where data is component's <code>data</code>
   *
   * @param {string} propertyName
   * @returns {object[]}
   * @ignore
   */
  function getFilterOptionsCP(propertyName) {
    return computed('data.@each.' + propertyName, function () {
      var data = get(this, 'data');
      var predefinedFilterOptions = get(this, 'predefinedFilterOptions');
      var filterWithSelect = get(this, 'filterWithSelect');
      if (filterWithSelect && 'array' !== typeOf(predefinedFilterOptions)) {
        var _data = A(A(data).compact());
        var options = A(_data.mapBy(propertyName)).compact();
        if (get(this, 'sortFilterOptions')) {
          options = options.sort();
        }
        return A([''].concat(_toConsumableArray(options))).uniq().map(optionStrToObj);
      }
      return [];
    });
  }

  /**
   *
   * @param {object[]} collection
   * @param {string} propertyName
   * @returns {object}
   */
  function groupBy(collection, propertyName) {
    var map = {};
    if (!isArray(collection)) {
      return map;
    }
    collection.forEach(function (item) {
      var value = get(item, propertyName);
      if (!isArray(map[value])) {
        map[value] = A([]);
      }
      map[value].pushObject(item);
    });
    return map;
  }

  function objToArray(map) {
    var ret = [];
    keys(map).forEach(function (k) {
      ret = [].concat(_toConsumableArray(ret), _toConsumableArray(map[k]));
    });
    return ret;
  }

  /**
   * Table-component with pagination, sorting and filtering.
   *
   * It should be used when whole dataset is already loaded. For server-side pagination, filtering and sorting
   * [models-table-server-paginated](Components.ModelsTableServerPaginated.html) should be used.
   *
   * Basic usage example:
   *
   * ```hbs
   * {{models-table data=model columns=columns}}
   * ```
   *
   * Usage with block context:
   *
   * ```hbs
   * {{#models-table data=data columns=columns as |mt|}}
   *   {{mt.global-filter}}
   *   {{mt.data-group-by-select}}
   *   {{mt.columns-dropdown}}
   *   {{mt.table}}
   *   {{mt.footer}}
   * {{/models-table}}
   * ```
   *
   * ModelsTable yields references to the following contextual components:
   *
   * * [models-table/global-filter](Components.ModelsTableGlobalFilter.html) - global filter used for table data
   * * [models-table/columns-dropdown](Components.ModelsTableColumnsDropdown.html) - dropdown with list of options to toggle columns and column-sets visibility
   * * [models-table/data-group-by-select](Components.ModelsTableDataGroupBySelect.html) - dropdown to select property for table-rows grouping
   * * [models-table/table](Components.ModelsTableTable.html) - table with a data
   * * [models-table/footer](Components.ModelsTableFooter.html) - summary and pagination
   *
   * Check own docs for each component to get detailed info.
   *
   * ModelsTable has a lot of options you may configure, but there are two required properties called `data` and `columns`. First one contains data (e.g. list of records from the store). Second one is a list of table's columns (check [models-table-column](Utils.ModelsTableColumn.html) for available options).
   *
   * @namespace Components
   * @class ModelsTable
   * @extends Ember.Component
   */
  exports.default = Component.extend({

    layout: _modelsTable.default,

    classNames: ['models-table-wrapper'],

    /**
     * Map with overrides for messages used in the component
     *
     * Available keys and values
     *
     *  * `searchLabel`: 'Search:',
     *  * `groupByLabel`: 'Group by:',
     *  * `searchPlaceholder`: '',
     *  * `columns-title`: 'Columns',
     *  * `columns-showAll`: 'Show All',
     *  * `columns-hideAll`: 'Hide All',
     *  * `columns-restoreDefaults`: 'Restore Defaults',
     *  * `tableSummary`: 'Show %@ - %@ of %@',
     *  * `allColumnsAreHidden`: 'All columns are hidden. Use <strong>columns</strong>-dropdown to show some of them',
     *  * `noDataToShow`: 'No records to show'
     *
     * @property customMessages
     * @default {}
     * @type object
     */

    /**
     * Number of records shown on one table-page
     *
     * @type number
     * @property pageSize
     * @default 10
     */
    pageSize: 10,

    /**
     * Currently shown page number. It may be set initially
     *
     * @type number
     * @property currentPageNumber
     * @default 1
     */
    currentPageNumber: 1,

    /**
     * List of properties to sort table rows
     *
     * Each value is like "propertyName:sortDirection"
     *
     * @type string[]
     * @property sortProperties
     * @default []
     * @private
     */
    sortProperties: computed(function () {
      return A([]);
    }),

    /**
     * @type string[]
     * @default ['processedColumns.@each.filterString', 'filterString', 'pageSize']
     * @private
     * @readonly
     */
    forceToFirstPageProps: computed(function () {
      return A(['processedColumns.@each.filterString', 'filterString', 'pageSize']);
    }).readOnly(),

    /**
     * Determines if multi-columns sorting should be used
     *
     * @type boolean
     * @property multipleColumnsSorting
     * @default false
     */
    multipleColumnsSorting: true,

    /**
     * Determines if component footer should be shown on the page
     *
     * @type boolean
     * @property showComponentFooter
     * @default true
     */
    showComponentFooter: true,

    /**
     * Determines if numeric pagination should be used
     *
     * @type boolean
     * @property useNumericPagination
     * @default false
     */
    useNumericPagination: false,

    /**
     * Determines if columns-dropdown should be shown
     *
     * @type boolean
     * @property showColumnsDropdown
     * @default true
     */
    showColumnsDropdown: true,

    /**
     * Determines if filtering by columns should be available to the user
     *
     * @type boolean
     * @property useFilteringByColumns
     * @default true
     */
    useFilteringByColumns: true,

    /**
     * Global filter value
     *
     * @type string
     * @property filterString
     * @default ''
     */
    filterString: '',

    /**
     * Determines if filtering (global and by column) should ignore case
     *
     * @type boolean
     * @property filteringIgnoreCase
     * @default false
     */
    filteringIgnoreCase: false,

    /**
     * Determines if filtering should be done by hidden columns
     *
     * **Notice:** after changing this value filtering results will be updated only after filter options are changed
     *
     * @type boolean
     * @property doFilteringByHiddenColumns
     * @default true
     */
    doFilteringByHiddenColumns: true,

    /**
     * Determines if "Global filter"-field should be shown
     *
     * @type boolean
     * @property showGlobalFilter
     * @default true
     */
    showGlobalFilter: true,

    /**
     * Determines if focus should be on the "Global filter"-field on component render
     *
     * @type boolean
     * @property focusGlobalFilter
     * @default false
     */
    focusGlobalFilter: false,

    /**
     * Determines if <code>processedColumns</code> will be updated if <code>columns</code> are changed (<code>propertyName</code> and
     * <code>template</code> are observed)
     * <b>IMPORTANT</b> All filter, sort and visibility options will be dropped to the default values while updating
     *
     * @type boolean
     * @property columnsAreUpdateable
     * @default false
     */
    columnsAreUpdateable: false,

    /**
     * Determines if rows should be grouped for some property
     *
     * Grouped value may be shown in the separated row on the top of the group or in the first column (in the cell with rowspan) in the each group (see {{#crossLink "Components.ModelsTable/displayGroupedValueAs:property"}}displayGroupedValueAs{{/crossLink}})
     *
     * Generally you should not show column with property which is used for grouping (but it's up to you)
     *
     * @property useDataGrouping
     * @type boolean
     * @default false
     */
    useDataGrouping: false,

    /**
     * Property name used now for grouping rows
     *
     * **IMPORTANT** It should be set initially if {{#crossLink "Components.ModelsTable/useDataGrouping:property"}}useDataGrouping{{/crossLink}} is set to `true`
     *
     * @property currentGroupingPropertyName
     * @type string
     * @default null
     */
    currentGroupingPropertyName: null,

    /**
     * Sort direction for grouped property values
     *
     * @property sortByGroupedFieldDirection
     * @type string
     * @default 'asc'
     * @private
     */
    sortByGroupedFieldDirection: 'asc',

    /**
     * Determines how grouped value will be displayed - as a row or column
     *
     * Allowed values are `row` and `column`
     *
     * @property displayGroupedValueAs
     * @type string
     * @default 'row'
     */
    displayGroupedValueAs: 'row',

    /**
     * <code>columns</code> fields which are observed to update shown table-columns
     * It is used only if <code>columnsAreUpdateable</code> is <code>true</code>
     *
     * @type string[]
     * @property columnFieldsToCheckUpdate
     * @default ['propertyName', 'component']
     */
    columnFieldsToCheckUpdate: computed(function () {
      return A(['propertyName', 'component']);
    }),

    /**
     * `themeInstance` is an instance of [DefaultTheme](Themes.Default.html) or it's children.
     * By default `models-table` uses [BootstrapTheme](Themes.Bootstrap.html) instance.
     *
     * You may create your own theme-class and set `themeInstance` to it's instance. Check Theme properties you may define in your own theme.
     *
     * @type Themes.Default
     * @property themeInstance
     */
    themeInstance: computed(function () {
      return _bootstrap.default.create();
    }),

    /**
     * All table records
     *
     * It's a first of the two attributes you must set to the component
     *
     * @type object[]
     * @property data
     * @default []
     */
    data: computed(function () {
      return A([]);
    }),

    /**
     * Table columns. Check [ModelsTableColumn](Utils.ModelsTableColumn.html) for available properties
     *
     * It's a second of the two attributes you must set to the component
     *
     * @type object[]
     * @property columns
     * @default []
     */
    columns: computed(function () {
      return A([]);
    }),

    /**
     * Hash of components to be used for columns.
     *
     * See [ModelsTableColumn](Utils.ModelsTableColumn.html), property component
     *
     * @type Object
     * @porperty columnComponents
     * @default {}
     */
    columnComponents: computed({
      get: function get() {
        return {};
      },
      set: function set(k, v) {
        return v;
      }
    }),

    /**
     * Sets of columns that can be toggled together.
     * Each object should have:
     *  * `label` (string) - The label for the set. This will be displayed in the columns dropdown.
     *  * `showColumns` (array|Function) - This should either be an array of `propertyNames` to show, or a function. If it is a function, the function will be called with the `processedColumns` as attribute.
     *  * `hideOtherColumns` (boolean) -  If this is true (default), all columns not specified in <code>showColumns</code> will be hidden. If this is set to false, other columns will be left at whatever visibility they were before.
     *  * `toggleSet` (boolean) - If this is true (default is false), the set columns will be shown if one of them is currently hidden,
     else they will all be hidden. Settings this will result in a default of `hideOtherColumns=false`
     *
     * @type Object[]
     * @property columnSets
     * @default []
     */
    columnSets: computed(function () {
      return A([]);
    }),

    /**
     * List of columns shown in the table. It's created from the {{#crossLink "Components.ModelsTable/columns:property"}}columns{{/crossLink}} provided to the component
     *
     * @type Object[]
     * @property processedColumns
     * @default []
     * @private
     */
    processedColumns: computed(function () {
      return A([]);
    }),

    /**
     * Default for messages used in the component.
     *
     * @type Object
     * @private
     * @property messages
     * @default {}
     */
    messages: computed(function () {
      return O.create({});
    }),

    /**
     * List of the additional headers. Used to group columns.
     *
     * Each object may have such fields:
     *
     * * `title` (string) - Header for grouped column
     * * `colspan` (number) - HTML colspan attr
     * * `rowspan` (number) - HTML rowspan attr
     *
     * @property groupedHeaders
     * @type groupedHeader[][]
     * @default []
     */
    groupedHeaders: computed(function () {
      return A([]);
    }),

    /**
     * Determines if page size should be shown
     *
     * @type boolean
     * @property showPageSize
     * @default true
     */
    showPageSize: true,

    /**
     * Expanded row items
     * It's set to the initial value when current page or page size is changed
     *
     * @type object[]
     * @property _expandedItems
     * @private
     */
    _expandedItems: null,

    /**
     * true - allow to expand more than 1 row
     * false - only 1 row may be expanded in the same time
     *
     * @type boolean
     * @property multipleExpand
     * @default false
     */
    multipleExpand: false,

    /**
     * List of currently selected row items
     *
     * Row may be selected by clicking on it, if {{#crossLink "Components.ModelsTable/selectRowOnClick:property"}}selectRowOnClick{{/crossLink}} is set to `true`
     *
     * @type object[]
     * @property _selectedItems
     * @private
     */
    _selectedItems: null,

    /**
     * List of grouped property values where the groups are collapsed
     *
     * @type array
     * @property _collapsedGroupValues
     * @private
     */
    _collapsedGroupValues: computed(function () {
      return A();
    }),

    /**
     * Allow or disallow to select rows on click
     * If `false` - no row can be selected
     *
     * @type boolean
     * @property selectRowOnClick
     * @default true
     */
    selectRowOnClick: true,

    /**
     * Allow or disallow to select multiple rows
     * If `false` - only one row may be selected in the same time
     *
     * @type boolean
     * @property multipleSelect
     * @default false
     */
    multipleSelect: false,

    /**
     * Component name used in the "expanded" row
     *
     * It will receive several options:
     * * `record` - current row value
     * * `processedColumns` - current column (one of the {{#crossLink "Components.ModelsTable/processedColumns:property"}}processedColumns{{/crossLink}})
     * * `messages` - bound from {{#crossLink "Components.ModelsTable/messages:property"}}messages{{/crossLink}}
     * * `index` - current row index
     * * `selectedItems` - bound from {{#crossLink "Components.ModelsTable/_selectedItems:property"}}_selectedItems{{/crossLink}}
     * * `visibleProcessedColumns` - bound from {{#crossLink "Components.ModelsTable/visibleProcessedColumns:property"}}visibleProcessedColumns{{/crossLink}}
     * * `clickOnRow` - closure action {{#crossLink "Components.ModelsTable/actions.clickOnRow:method"}}ModelsTable.actions.clickOnRow{{/crossLink}}
     * * `sendAction` - closure action {{#crossLink "Components.ModelsTable/actions.sendAction:method"}}ModelsTable.actions.sendAction{{/crossLink}}
     * * `themeInstance` - bound from {{#crossLink "Components.ModelsTable/themeInstance:property"}}themeInstance{{/crossLink}}
     *
     * @type string
     * @property expandedRowComponent
     * @default ''
     */
    expandedRowComponent: '',

    /**
     * Component name used in the row with a grouped value
     *
     * This component won't be used if {{#crossLink "Component.ModelsTable/useDataGrouping:property"}}useDataGrouping{{/crossLink}} is not `true`
     *
     * Component will receive several options:
     *
     * * `groupedValue` - grouped property value
     * * `currentGroupingPropertyName` - bound from {{#crossLink "Components.ModelsTable/currentGroupingPropertyName:property"}}currentGroupingPropertyName{{/crossLink}}
     * * `displayGroupedValueAs` - bound from {{#crossLink "Components.ModelsTable/displayGroupedValueAs:property"}}ModelsTable.displayGroupedValueAs{{/crossLink}}
     * * `toggleGroupedRows` - closure action {{#crossLink "Components.ModelsTable/actions.toggleGroupedRows:method"}}ModelsTable.actions.toggleGroupedRows{{/crossLink}}
     * * `toggleGroupedRowsExpands` - closure action {{#crossLink "Components.ModelsTable/actions.toggleGroupedRowsExpands:method"}}ModelsTable.actions.toggleGroupedRowsExpands{{/crossLink}}
     * * `toggleGroupedRowsSelection` - closure action {{#crossLink "Components.ModelsTable/actions.toggleGroupedRowsSelection:method"}}ModelsTable.actions.toggleGroupedRowsSelection{{/crossLink}}
     * * `visibleProcessedColumns` - bound from {{#crossLink "Components.ModelsTable/visibleProcessedColumns:property"}}ModelsTable.visibleProcessedColumns{{/crossLink}}
     * * `themeInstance` - bound from {{#crossLink "Components.ModelsTable/themeInstance:property"}}ModelsTable.themeInstance{{/crossLink}}
     * * `sendAction` - closure action {{#crossLink "Components.ModelsTable/actions.sendAction:method"}}ModelsTable.actions.sendAction{{/crossLink}}
     * * `groupedItems` - list of all rows group items
     * * `visibleGroupedItems` - list of rows group items shown on the current table page
     * * `selectedGroupedItems` - list of selected rows group items
     * * `expandedGroupedItems` - list of expanded rows group items
     *
     * @type string
     * @property groupingRowComponent
     * @default ''
     */
    groupingRowComponent: '',

    /**
     * Action-name sent on user interaction
     *
     * Action will send one parameter - object with fields:
     *
     * * `sort` - list with sort value `propertyName:sortDirection`
     * * `currentPageNumber` - currently shown page number
     * * `pageSize` - current page size
     * * `filterString` - global filter value
     * * `filteredContent` - filtered data
     * * `selectedItems` - list with selected row items
     * * `expandedItems` - list with expanded row items
     * * `columnFilters` - hash with fields equal to filtered propertyName and values equal to filter values
     *
     * @type string
     * @property displayDataChangedAction
     * @default 'displayDataChanged'
     */
    displayDataChangedAction: 'displayDataChanged',

    /**
     * Determines if action on user interaction should be sent
     *
     * @default false
     * @property sendDisplayDataChangedAction
     * @type boolean
     */
    sendDisplayDataChangedAction: false,

    /**
     * Action-name sent on change of visible columns
     *
     * The action will receive an array of objects as parameter, where every object looks like this: `{ propertyName: 'firstName', isHidden: true, mayBeHidden: false }`
     *
     * @type string
     * @property columnsVisibilityChangedAction
     * @default 'columnsVisibilityChanged'
     */
    columnsVisibilityChangedAction: 'columnsVisibilityChanged',

    /**
     * Determines if action on change of visible columns should be sent
     *
     * @default false
     * @property sendColumnsVisibilityChangedAction
     * @type boolean
     */
    sendColumnsVisibilityChangedAction: false,

    /**
     * Determines if action should be sent when user did double click in row
     *
     * @default false
     * @type boolean
     * @property sendRowDoubleClick
     */
    sendRowDoubleClick: false,

    /**
     * Action-name sent on row double-click
     *
     * @type string
     * @default 'rowDoubleClick'
     * @property rowDoubleClickAction
     */
    rowDoubleClickAction: 'rowDoubleClick',

    /**
     * Determines if action should be sent when user hover or out from row for a given period of time
     *
     * @type boolean
     * @default false
     * @property sendRowHover
     */
    sendRowHover: false,

    /**
     * Action-name sent on row hover
     *
     * @type string
     * @property rowHoverAction
     * @default 'rowHover'
     */
    rowHoverAction: 'rowHover',

    /**
     * Action-name sent on row out
     *
     * @type string
     * @property rowOutAction
     * @default 'rowOut'
     */
    rowOutAction: 'rowOut',

    /**
     * Rows with this items should be preselected on component init
     * It's NOT a list of indexes!
     *
     * @default null
     * @property preselectedItems
     * @type object[]|null
     */
    preselectedItems: null,

    /**
     * List of the currently visible columns
     *
     * @type Object[]
     * @property visibleProcessedColumns
     * @default []
     * @private
     */
    visibleProcessedColumns: computed.filterBy('processedColumns', 'isVisible', true),

    /**
     * True if all processedColumns are hidden by <code>isHidden</code>
     *
     * @type boolean
     * @property allColumnsAreHidden
     * @readonly
     * @private
     */
    allColumnsAreHidden: computed('processedColumns.@each.isHidden', function () {
      var processedColumns = get(this, 'processedColumns');
      return processedColumns.length > 0 && processedColumns.isEvery('isHidden', true);
    }).readOnly(),

    /**
     * List of property names can be used for grouping
     *
     * It may be a list of strings of list of objects. In first case label and value in the select-box will be the same.
     * In the second case you must set `label` and `value` properties for each list item
     *
     * **IMPORTANT** It must contain {{#crossLink "Components.ModelsTable/currentGroupingPropertyName:property"}}currentGroupingPropertyName{{/crossLink}}-value
     *
     * @property dataGroupProperties
     * @type string[]|object[]
     * @default []
     */
    dataGroupProperties: computed(function () {
      return A([]);
    }),

    /**
     * @property dataGroupOptions
     * @type object[]
     * @private
     * @readonly
     */
    dataGroupOptions: computed('dataGroupProperties.[]', function () {
      return get(this, 'dataGroupProperties').map(function (item) {
        return 'object' === typeOf(item) || 'instance' === typeOf(item) ? item : { label: propertyNameToTitle(item), value: item };
      });
    }).readOnly(),

    /**
     * `true` if some value is set to the global filter
     *
     * @type boolean
     * @property globalFilterUsed
     * @readonly
     * @private
     */
    globalFilterUsed: computed.notEmpty('filterString'),

    /**
     * `true` if global filter or filter by any column is used
     *
     * @type boolean
     * @property anyFilterUsed
     * @readonly
     * @private
     */
    anyFilterUsed: computed('globalFilterUsed', 'processedColumns.@each.filterUsed', function () {
      return get(this, 'globalFilterUsed') || get(this, 'processedColumns').isAny('filterUsed');
    }).readOnly(),

    /**
     * `true` if all processedColumns don't use filtering and sorting
     *
     * @type boolean
     * @property noHeaderFilteringAndSorting
     * @readonly
     * @private
     */
    noHeaderFilteringAndSorting: computed('processedColumns.@each.{useSorting,useFilter}', function () {
      var processedColumns = get(this, 'processedColumns');
      return processedColumns.isEvery('useFilter', false) && processedColumns.isEvery('useSorting', false);
    }).readOnly(),

    /**
     * Number of pages
     *
     * @type number
     * @property pagesCount
     * @readonly
     * @private
     */
    pagesCount: computed('arrangedContent.[]', 'pageSize', function () {
      var pagesCount = get(this, 'arrangedContent.length') / parseInt(get(this, 'pageSize'), 10);
      return 0 === pagesCount % 1 ? pagesCount : Math.floor(pagesCount) + 1;
    }).readOnly(),

    /**
     * {{#crossLink "Components.ModelsTable/data:property"}}data{{/crossLink}} filtered with a global filter and columns filters
     *
     * Filtering by columns is ignored if {{#crossLink "Components.ModelsTable/useFilteringByColumns:property"}}useFilteringByColumns{{/crossLink}} is set to `false`
     *
     * @type Object[]
     * @property filteredContent
     * @readonly
     * @private
     */
    filteredContent: computed('filterString', 'data.[]', 'useFilteringByColumns', 'processedColumns.@each.filterString', function () {
      var processedColumns = get(this, 'processedColumns');
      var data = get(this, 'data');
      var useFilteringByColumns = get(this, 'useFilteringByColumns');
      var filteringIgnoreCase = get(this, 'filteringIgnoreCase');
      var doFilteringByHiddenColumns = get(this, 'doFilteringByHiddenColumns');
      if (!isArray(data)) {
        return [];
      }
      if (!get(this, 'anyFilterUsed')) {
        return data.slice();
      }
      var filterString = get(this, 'filterString');
      if (filteringIgnoreCase) {
        filterString = filterString.toLowerCase();
      }

      var _processedColumns = A(processedColumns.filterBy('useFilter'));
      if (!doFilteringByHiddenColumns) {
        _processedColumns = A(_processedColumns.filterBy('isHidden', false));
      }
      if (!_processedColumns.length) {
        return data.slice();
      }

      // global search
      var filtersFor = A(A(_processedColumns.mapBy('filterField')).compact());
      var globalSearch = data.filter(function (row) {
        return filtersFor.any(function (filterFor) {
          var cellValue = '' + get(row, filterFor);
          if (filteringIgnoreCase) {
            cellValue = cellValue.toLowerCase();
          }
          return -1 !== cellValue.indexOf(filterString);
        });
      });

      if (!useFilteringByColumns) {
        return globalSearch;
      }

      // search by each column
      _processedColumns = _processedColumns.filterBy('filterField').filter(function (c) {
        return !(get(c, 'filterWithSelect') && '' === get(c, 'filterString'));
      });
      return globalSearch.filter(function (row) {
        return _processedColumns.every(function (c) {
          var filterFor = get(c, 'filterField');
          var cellValue = '' + get(row, filterFor);
          var filterString = get(c, 'filterString');
          if (filteringIgnoreCase) {
            cellValue = typeOf(cellValue) === 'string' ? cellValue.toLowerCase() : cellValue;
            filterString = typeOf(filterString) === 'string' ? filterString.toLowerCase() : filterString;
          }
          return 'function' === typeOf(c.filterFunction) ? c.filterFunction(cellValue, filterString, row) : 0 === compare(cellValue, filterString);
        });
      });
    }),

    /**
     * {{#crossLink "Components.ModelsTable/filteredContent:property"}}filteredContent{{/crossLink}} sorted by needed properties
     *
     * @type Object[]
     * @property arrangedContent
     * @readonly
     * @private
     */
    arrangedContent: computed('filteredContent.[]', 'sortProperties.[]', function () {
      var filteredContent = get(this, 'filteredContent');
      var sortProperties = get(this, 'sortProperties').map(function (p) {
        var _p$split = p.split(':'),
            _p$split2 = _slicedToArray(_p$split, 2),
            prop = _p$split2[0],
            direction = _p$split2[1];

        direction = direction || 'asc';

        return [prop, direction];
      });

      var _filteredContent = filteredContent.slice();
      return sortProperties.length ? _filteredContent.sort(function (row1, row2) {
        for (var i = 0; i < sortProperties.length; i++) {
          var _sortProperties$i = _slicedToArray(sortProperties[i], 2),
              prop = _sortProperties$i[0],
              direction = _sortProperties$i[1];

          var result = prop ? (0, _betterCompare.default)(get(row1, prop), get(row2, prop)) : 0;
          if (result !== 0) {
            return direction === 'desc' ? -1 * result : result;
          }
        }

        return 0;
      }) : _filteredContent;
    }),

    /**
     * {{#crossLink "Components.ModelsTable/filteredContent:property"}}filteredContent{{/crossLink}} grouped by {{#crossLink "Components.ModelsTable/currentGroupingPropertyName:property"}}currentGroupingPropertyName{{/crossLink}} sorted by needed properties
     *
     * @property groupedArrangedContent
     * @type object[]
     * @private
     * @readonly
     */
    groupedArrangedContent: computed('filteredContent.[]', 'sortProperties.[]', 'useDataGrouping', 'currentGroupingPropertyName', 'sortByGroupedFieldDirection', function () {
      var useDataGrouping = get(this, 'useDataGrouping');
      var currentGroupingPropertyName = get(this, 'currentGroupingPropertyName');
      var filteredContent = get(this, 'filteredContent');
      var sortByGroupedFieldDirection = get(this, 'sortByGroupedFieldDirection');
      var grouped = {};
      if (!useDataGrouping || !isArray(filteredContent)) {
        return grouped;
      }
      var sortProperties = get(this, 'sortProperties').map(function (p) {
        var _p$split3 = p.split(':'),
            _p$split4 = _slicedToArray(_p$split3, 2),
            prop = _p$split4[0],
            direction = _p$split4[1];

        direction = direction || 'asc';
        return [prop, direction];
      });

      var _filteredContent = filteredContent.slice();
      grouped = groupBy(_filteredContent, currentGroupingPropertyName);

      keys(grouped).map(function (k) {
        grouped[k] = sortProperties.length ? A(grouped[k].sort(function (row1, row2) {
          for (var i = 0; i < sortProperties.length; i++) {
            var _sortProperties$i2 = _slicedToArray(sortProperties[i], 2),
                prop = _sortProperties$i2[0],
                direction = _sortProperties$i2[1];

            var result = prop ? (0, _betterCompare.default)(get(row1, prop), get(row2, prop)) : 0;
            if (result !== 0) {
              return direction === 'desc' ? -1 * result : result;
            }
          }
          return 0;
        })) : grouped[k];
      });
      return keys(grouped).sort(function (v1, v2) {
        var result = (0, _betterCompare.default)(v1, v2);
        if (result !== 0) {
          return sortByGroupedFieldDirection === 'desc' ? -1 * result : result;
        }
        return 0;
      }).reduce(function (result, key) {
        return A([].concat(_toConsumableArray(result), _toConsumableArray(grouped[key])));
      }, A([]));
    }),

    /**
     * Content of the current table page
     *
     * {{#crossLink "Components.ModelsTable/arrangedContent:property"}}arrangedContent{{/crossLink}} sliced for currently shown page
     *
     * @type Object[]
     * @property visibleContent
     * @readonly
     * @private
     */
    visibleContent: computed('arrangedContent.[]', 'pageSize', 'currentPageNumber', function () {
      var arrangedContent = get(this, 'arrangedContent');
      var pageSize = parseInt(get(this, 'pageSize'), 10);
      var currentPageNumber = get(this, 'currentPageNumber');
      var startIndex = pageSize * (currentPageNumber - 1);
      if (get(arrangedContent, 'length') < pageSize) {
        return arrangedContent;
      }
      return arrangedContent.slice(startIndex, startIndex + pageSize);
    }),

    /**
     * Content of the current table page when rows grouping is used
     *
     * {{#crossLink "Components.ModelsTable/groupedVisibleContent:property"}}groupedVisibleContent{{/crossLink}} sliced for currently shown page
     *
     * @property groupedVisibleContent
     * @default {}
     * @type object
     * @private
     * @readonly
     */
    groupedVisibleContent: computed('groupedArrangedContent', 'pageSize', 'currentPageNumber', 'useDataGrouping', 'currentGroupingPropertyName', function () {
      var useDataGrouping = get(this, 'useDataGrouping');
      var currentGroupingPropertyName = get(this, 'currentGroupingPropertyName');
      var groupedArrangedContent = get(this, 'groupedArrangedContent');
      var pageSize = parseInt(get(this, 'pageSize'), 10);
      var currentPageNumber = get(this, 'currentPageNumber');
      var map = {};
      if (!useDataGrouping) {
        return map;
      }
      var startIndex = pageSize * (currentPageNumber - 1);
      return get(groupedArrangedContent, 'length') < pageSize ? groupBy(groupedArrangedContent, currentGroupingPropertyName) : groupBy(groupedArrangedContent.slice(startIndex, startIndex + pageSize), currentGroupingPropertyName);
    }),

    /**
     * List of grouped property values in order to show groups in the table
     *
     * @type array
     * @property groupedVisibleContentValuesOrder
     * @private
     * @readonly
     */
    groupedVisibleContentValuesOrder: computed('groupedVisibleContent.[]', 'sortByGroupedFieldDirection', function () {
      var sortByGroupedFieldDirection = get(this, 'sortByGroupedFieldDirection');
      var groupedVisibleContent = get(this, 'groupedVisibleContent');
      return keys(groupedVisibleContent).sort(function (v1, v2) {
        var result = (0, _betterCompare.default)(v1, v2);
        if (result !== 0) {
          return sortByGroupedFieldDirection === 'desc' ? -1 * result : result;
        }
        return 0;
      });
    }),

    /**
     * Is user on the last page
     *
     * @type boolean
     * @property isLastPage
     * @readonly
     * @private
     */
    isLastPage: computed('currentPageNumber', 'pagesCount', function () {
      return get(this, 'currentPageNumber') >= get(this, 'pagesCount');
    }).readOnly(),

    /**
     * Alias to <code>arrangedContent.length</code>
     *
     * @type number
     * @property arrangedContentLength
     * @readonly
     * @private
     */
    arrangedContentLength: computed.alias('arrangedContent.length'),

    /**
     * Index of the first currently shown record
     *
     * @type number
     * @property firstIndex
     * @private
     * @readonly
     */
    firstIndex: computed('arrangedContentLength', 'pageSize', 'currentPageNumber', function () {
      return 0 === get(this, 'arrangedContentLength') ? 0 : parseInt(get(this, 'pageSize'), 10) * (get(this, 'currentPageNumber') - 1) + 1;
    }).readOnly(),

    /**
     * Index of the last currently shown record
     *
     * @type number
     * @property lastIndex
     * @readonly
     * @private
     */
    lastIndex: computed('isLastPage', 'arrangedContentLength', 'currentPageNumber', 'pageSize', function () {
      return get(this, 'isLastPage') ? get(this, 'arrangedContentLength') : get(this, 'currentPageNumber') * parseInt(get(this, 'pageSize'), 10);
    }).readOnly(),

    /**
     * List of possible <code>pageSize</code> values. Used to change size of <code>visibleContent</code>
     *
     * @type number[]
     * @default [10, 25, 50]
     * @property pageSizeValues
     */
    pageSizeValues: computed(function () {
      return A([10, 25, 50]);
    }),

    /**
     * List of options for pageSize-selectBox
     * It's mapped from <code>pageSizeValues</code>
     * This value should not be set manually!
     *
     * @type {value: string|number, label: string|number}
     * @property pageSizeOptions
     * @default []
     * @private
     */
    pageSizeOptions: computed(function () {
      return A([]);
    }),

    /**
     * These are options for the columns dropdown.
     * By default, the "Show All", 'Hide All" and "Restore Defaults" buttons are displayed.
     *
     * @type { showAll: boolean, hideAll: boolean, restoreDefaults: boolean, columnSets: object[] }
     * @property columnDropdownOptions
     * @readonly
     * @private
     */
    columnDropdownOptions: computed('columnSets.{label,showColumns,hideOtherColumns}', function () {
      return O.create({
        showAll: true,
        hideAll: true,
        restoreDefaults: true,
        columnSets: A(get(this, 'columnSets') || [])
      });
    }),

    /**
     * Show first page if for some reasons there is no content for current page, but table data exists
     *
     * @method visibleContentObserver
     * @returns {undefined}
     * @private
     */
    visibleContentObserver: function visibleContentObserver() {
      run.once(this, this.visibleContentObserverOnce);
    },


    /**
     * @method visibleContentObserverOnce
     * @returns {undefined}
     * @private
     */
    visibleContentObserverOnce: function visibleContentObserverOnce() {
      var visibleContentLength = get(this, 'visibleContent.length');
      var dataLength = get(this, 'data.length');
      var currentPageNumber = get(this, 'currentPageNumber');
      if (!visibleContentLength && dataLength && currentPageNumber !== 1) {
        set(this, 'currentPageNumber', 1);
      }
    },


    /**
     * Component init
     *
     * Set visibility and filtering attributes for each column
     *
     * Update messages used by table with user-provided {{#crossLink "Components.ModelsTable/customMessages:property"}}messages{{/crossLink}}
     *
     * @method setup
     * @returns {undefined}
     */
    setup: on('init', function () {
      var _this = this;

      this._setupSelectedRows();
      this._setupExpandedRows();
      this._setupColumns();
      this._setupMessages();
      this._setupPageSizeOptions();

      if (get(this, 'columnsAreUpdateable')) {
        var columnFieldsToCheckUpdate = get(this, 'columnFieldsToCheckUpdate');
        (true && !('array' === typeOf(columnFieldsToCheckUpdate)) && Ember.assert('`columnFieldsToCheckUpdate` should be an array of strings', 'array' === typeOf(columnFieldsToCheckUpdate)));

        columnFieldsToCheckUpdate.forEach(function (propertyName) {
          return _this.addObserver('columns.@each.' + propertyName, _this, _this._setupColumnsOnce);
        });
      }
      this.addObserver('visibleContent.length', this, this.visibleContentObserver);
    }),

    /**
     * Recalculate processedColumns when the columns attr changes
     *
     * @method updateColumns
     */
    updateColumns: on('didReceiveAttrs', function () {
      if (get(this, 'columnsAreUpdateable')) {
        this._setupColumns();
      }
    }),

    /**
     * Focus on "Global filter" on component render
     *
     * @method focus
     */
    focus: on('didInsertElement', function () {
      if (get(this, 'showGlobalFilter') && get(this, 'focusGlobalFilter')) {
        jQ('.filterString').focus();
      }
    }),

    /**
     * Preselect table rows if `preselectedItems` is provided
     * `multipleSelected` may be set `true` if `preselectedItems` has more than 1 item
     *
     * @private _setupSelectedRows
     * @returns {undefined}
     * @method
     */
    _setupSelectedRows: function _setupSelectedRows() {
      set(this, '_selectedItems', A([]));
      var preselectedItems = get(this, 'preselectedItems');
      if (isArray(preselectedItems)) {
        set(this, '_selectedItems', A(preselectedItems));
        if (preselectedItems.length > 1 && !get(this, 'multipleSelected')) {
          (true && Ember.warn('`multipleSelected` is set `true`, because you have provided multiple `preselectedItems`.', false, { id: '#multipleSelected_autoset' }));

          set(this, 'multipleSelected', true);
        }
      }
    },


    /**
     * @method _setupExpandedRows
     * @returns {undefined}
     * @private
     */
    _setupExpandedRows: function _setupExpandedRows() {
      set(this, '_expandedItems', A([]));
    },


    /**
     * Wrapper for <code>_setupColumns</code> to call it only once when observer is fired
     *
     * @method _setupColumnsOnce
     * @returns {undefined}
     * @private
     */
    _setupColumnsOnce: function _setupColumnsOnce() {
      run.once(this, this._setupColumns);
    },


    /**
     * Create a column.
     * This can be overwritten if you need to use your own column object.
     *
     * @method _createColumn
     * @param {object} options
     * @returns {Object}
     */
    _createColumn: function _createColumn(options) {
      return _column.default.create(options);
    },


    /**
     * Create new properties for <code>columns</code> (filterString, useFilter, isVisible, defaultVisible)
     *
     * @method _setupColumns
     * @returns {undefined}
     * @private
     */
    _setupColumns: function _setupColumns() {
      var _this2 = this;

      var self = this;

      var nColumns = A(get(this, 'columns').map(function (column) {
        var filterFunction = get(column, 'filterFunction');
        filterFunction = 'function' === typeOf(filterFunction) ? filterFunction : defaultFilter;

        var c = _this2._createColumn(column);
        var propertyName = get(c, 'propertyName');
        setProperties(c, {
          data: get(_this2, 'data'),
          filterString: get(c, 'filterString') || '',
          originalDefinition: column
        });

        var columnComponents = get(_this2, "columnComponents");
        if (isPresent(columnComponents)) {
          var componentName = get(column, "component");
          if (isPresent(componentName)) {
            var hashComponent = get(columnComponents, componentName);
            if (isPresent(hashComponent)) {
              set(c, 'component', hashComponent);
            }
          }
        }

        set(c, 'filterFunction', filterFunction);

        if (isNone(get(c, 'mayBeHidden'))) {
          set(c, 'mayBeHidden', true);
        }

        var sortDirection = column.sortDirection,
            sortPrecedence = column.sortPrecedence;

        var hasSortPrecedence = !isNone(sortPrecedence) && sortPrecedence > NOT_SORTED;
        var defaultSortPrecedence = hasSortPrecedence ? sortPrecedence : NOT_SORTED;
        var defaultSorting = sortDirection && sortPrecedence > NOT_SORTED ? sortDirection.toLowerCase() : 'none';

        setProperties(c, {
          defaultVisible: !get(c, 'isHidden'),
          sorting: defaultSorting,
          sortPrecedence: defaultSortPrecedence
        });

        if (get(c, 'filterWithSelect') && get(c, 'useFilter')) {
          var predefinedFilterOptions = get(column, 'predefinedFilterOptions');
          var usePredefinedFilterOptions = 'array' === typeOf(predefinedFilterOptions);
          if (usePredefinedFilterOptions && get(predefinedFilterOptions, 'length')) {
            var types = A(['object', 'instance']);
            var allObjects = A(predefinedFilterOptions).every(function (option) {
              return types.includes(typeOf(option)) && option.hasOwnProperty('label') && option.hasOwnProperty('value');
            });
            var allPrimitives = A(predefinedFilterOptions).every(function (option) {
              return !types.includes(typeOf(option));
            });
            (true && !(allObjects || allPrimitives) && Ember.assert('`predefinedFilterOptions` should be an array of objects or primitives and not mixed', allObjects || allPrimitives));

            if (allPrimitives) {
              predefinedFilterOptions = predefinedFilterOptions.map(optionStrToObj);
            }
            if ('' !== predefinedFilterOptions[0].value) {
              predefinedFilterOptions = [{ value: '', label: '' }].concat(_toConsumableArray(predefinedFilterOptions));
            }
            set(c, 'filterOptions', usePredefinedFilterOptions ? predefinedFilterOptions : []);
          } else if (usePredefinedFilterOptions) {
            // Empty array as predefined filter
            set(c, 'useFilter', false);
          } else {
            if (propertyName) {
              set(c, 'filterOptions', getFilterOptionsCP(propertyName));
            }
          }
          var filterOptions = get(c, 'filterOptions');
          var placeholder = get(c, 'filterPlaceholder');
          if (isArray(filterOptions) && placeholder && !filterOptions[0].label) {
            set(c, 'filterOptions.firstObject.label', placeholder);
          }
        }
        return c;
      }));
      nColumns.filterBy('propertyName').forEach(function (column) {
        var propertyName = get(column, 'propertyName');
        if (isNone(get(column, 'title'))) {
          set(column, 'title', propertyNameToTitle(propertyName));
        }
      });
      set(this, 'processedColumns', nColumns);

      // Apply initial sorting
      set(this, 'sortProperties', A());
      var filteredOrderedColumns = nColumns.sortBy('sortPrecedence').filter(function (col) {
        return isSortedByDefault(col);
      });
      filteredOrderedColumns.forEach(function (column) {
        self.send('sort', column);
        var defaultSortedBy = column.sortedBy || column.propertyName;
        var sortingArgs = [column, defaultSortedBy, column.sortDirection.toLowerCase()];
        if (get(_this2, 'multipleColumnsSorting')) {
          _this2._multiColumnsSorting.apply(_this2, sortingArgs);
        } else {
          _this2._singleColumnSorting.apply(_this2, sortingArgs);
        }
      });
    },


    /**
     * Update messages used by widget with custom values provided by user in the <code>customMessages</code>
     *
     * @method _setupMessages
     * @returns {undefined}
     * @private
     */
    _setupMessages: observer('customMessages', function () {
      var customMessages = getWithDefault(this, 'customMessages', {});
      var newMessages = {};
      assign(newMessages, defaultMessages, customMessages);
      set(this, 'messages', O.create(newMessages));
    }),

    /**
     * Provide backward compatibility with <code>pageSizeValues</code> equal to an array with numbers and not objects
     * <code>pageSizeValues</code> is live as is, <code>pageSizeOptions</code> is used in the templates
     *
     * @method _setupPageSizeOptions
     * @returns {undefined}
     * @private
     */
    _setupPageSizeOptions: function _setupPageSizeOptions() {
      var pageSizeOptions = get(this, 'pageSizeValues').map(optionStrToObj);
      set(this, 'pageSizeOptions', pageSizeOptions);
    },


    /**
     * Set <code>sortProperties</code> when single-column sorting is used
     *
     * @param {ModelsTableColumn} column
     * @param {string} sortedBy
     * @param {string} newSorting 'asc|desc|none'
     * @method _singleColumnSorting
     * @returns {undefined}
     * @private
     */
    _singleColumnSorting: function _singleColumnSorting(column, sortedBy, newSorting) {
      get(this, 'processedColumns').setEach('sorting', 'none');
      set(column, 'sorting', newSorting);
      set(this, 'sortProperties', 'none' === newSorting ? [] : [sortedBy + ':' + newSorting]);
    },


    /**
     * Set <code>sortProperties</code> when multi-columns sorting is used
     *
     * @param {ModelsTableColumn} column
     * @param {string} sortedBy
     * @param {string} newSorting 'asc|desc|none'
     * @method _multiColumnsSorting
     * @returns {undefined}
     * @private
     */
    _multiColumnsSorting: function _multiColumnsSorting(column, sortedBy, newSorting) {
      set(column, 'sorting', newSorting);
      var sortProperties = get(this, 'sortProperties');
      var sortPropertiesMap = {};
      sortProperties.forEach(function (p) {
        var _p$split5 = p.split(':'),
            _p$split6 = _slicedToArray(_p$split5, 2),
            propertyName = _p$split6[0],
            order = _p$split6[1];

        sortPropertiesMap[propertyName] = order;
      });
      delete sortPropertiesMap[sortedBy];

      var newSortProperties = A([]);
      keys(sortPropertiesMap).forEach(function (propertyName) {
        if (propertyName !== sortedBy) {
          newSortProperties.pushObject(propertyName + ':' + sortPropertiesMap[propertyName]);
        }
      });
      if ('none' !== newSorting) {
        newSortProperties.pushObject(sortedBy + ':' + newSorting);
      }
      set(this, 'sortProperties', newSortProperties);
    },


    /**
     * send <code>displayDataChangedAction</code>-action when user does sort of filter
     * action is sent only if <code>sendDisplayDataChangedAction</code> is true (default false)
     *
     * @method userInteractionObserver
     * @returns {undefined}
     * @private
     */
    userInteractionObserver: function userInteractionObserver() {
      run.once(this, this.userInteractionObserverOnce);
    },


    /**
     * @method userInteractionObserverOnce
     * @returns {undefined}
     * @private
     */
    userInteractionObserverOnce: function userInteractionObserverOnce() {
      if (get(this, 'sendDisplayDataChangedAction')) {
        var columns = get(this, 'processedColumns');
        var settings = O.create({
          sort: get(this, 'sortProperties'),
          currentPageNumber: get(this, 'currentPageNumber'),
          pageSize: parseInt(get(this, 'pageSize'), 10),
          filterString: get(this, 'filterString'),
          filteredContent: get(this, 'filteredContent'),
          selectedItems: get(this, '_selectedItems'),
          expandedItems: get(this, '_expandedItems'),
          columns: columns.map(function (c) {
            return getProperties(c, ['filterString', 'filterField', 'sortField', 'sorting', 'propertyName']);
          }),
          columnFilters: {}
        });
        columns.forEach(function (column) {
          if (!isBlank(get(column, 'filterString'))) {
            settings.columnFilters[get(column, 'propertyName')] = get(column, 'filterString');
          }
        });
        this.sendAction('displayDataChangedAction', settings);
      }
    },


    /**
     * send <code>columnsVisibilityChangedAction</code>-action when user changes which columns are visible
     * action is sent only if <code>sendColumnsVisibilityChangedAction</code> is true (default false)
     *
     * @returns {undefined}
     * @method _sendColumnsVisibilityChangedAction
     * @private
     */
    _sendColumnsVisibilityChangedAction: function _sendColumnsVisibilityChangedAction() {
      if (get(this, 'sendColumnsVisibilityChangedAction')) {
        var columns = get(this, 'processedColumns');
        var columnsVisibility = columns.map(function (column) {
          var options = getProperties(column, 'isHidden', 'mayBeHidden', 'propertyName');
          options.isHidden = !!options.isHidden;
          return options;
        });
        this.sendAction('columnsVisibilityChangedAction', columnsVisibility);
      }
    },


    /**
     * Handler for global filter and filter by each column
     *
     * @method forceToFirstPage
     * @returns {undefined}
     * @private
     */
    forceToFirstPage: function forceToFirstPage() {
      set(this, 'currentPageNumber', 1);
      this.userInteractionObserver();
    },


    /**
     * Collapse open rows when user change page size or moved to the another page
     *
     * @method collapseRowOnNavigate
     * @returns {undefined}
     * @private
     */
    collapseRowOnNavigate: observer('currentPageNumber', 'pageSize', function () {
      set(this, '_expandedItems', A([]));
    }),

    /**
     * Rebuild the whole table.
     * This can be called to force a complete re-render of the table.
     *
     * @method rebuildTable
     * @returns {undefined}
     */
    rebuildTable: function rebuildTable() {
      set(this, 'currentPageNumber', 1);
      this._clearFilters();
      this.setup();
    },


    /**
     * Clear all filters.
     *
     * @method _clearFilters
     * @returns {undefined}
     * @private
     */
    _clearFilters: function _clearFilters() {
      set(this, 'filterString', '');
      get(this, 'processedColumns').setEach('filterString', '');
    },
    willInsertElement: function willInsertElement() {
      var _this3 = this;

      get(this, 'forceToFirstPageProps').forEach(function (propertyName) {
        return _this3.addObserver(propertyName, _this3.forceToFirstPage);
      });
      return this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      var _this4 = this;

      get(this, 'forceToFirstPageProps').forEach(function (propertyName) {
        return _this4.removeObserver(propertyName, _this4.forceToFirstPage);
      });
      return this._super.apply(this, arguments);
    },


    /**
     * @type Object
     */
    actions: {

      /**
       * Send action to the outside context
       *
       * `sendAction` signature is the same as `Ember.Component#sendAction`
       *
       * @method actions.sendAction
       * @returns {undefined}
       */
      sendAction: function sendAction() {
        this.sendAction.apply(this, arguments);
      },


      /**
       * Toggle visibility for provided column
       *
       * It doesn't do nothing if column can't be hidden (see {{#crossLink "Utils.ModelsTableColumn/mayBeHidden:property"}}mayBeHidden{{/crossLink}}). May trigger sending {{#crossLink "Components.ModelsTable/columnsVisibilityChangedAction:property"}}columnsVisibilityChangedAction{{/crossLink}}
       *
       * @method actions.toggleHidden
       * @param {ModelsTableColumn} column
       * @returns {undefined}
       */
      toggleHidden: function toggleHidden(column) {
        if (get(column, 'mayBeHidden')) {
          column.toggleProperty('isHidden');
          this._sendColumnsVisibilityChangedAction();
        }
      },


      /**
       * Show all columns
       *
       * Set each column `isHidden` value to `false`. May trigger sending {{#crossLink "Components.ModelsTable/columnsVisibilityChangedAction:property"}}columnsVisibilityChangedAction{{/crossLink}}
       *
       * @method actions.showAllColumns
       * @returns {undefined}
       */
      showAllColumns: function showAllColumns() {
        get(this, 'processedColumns').setEach('isHidden', false);
        this._sendColumnsVisibilityChangedAction();
      },


      /**
       * Hide all columns that may be hidden (see {{#crossLink "Utils.ModelsTableColumn/mayBeHidden:property"}}mayBeHidden{{/crossLink}})
       *
       * May trigger sending {{#crossLink "Components.ModelsTable/columnsVisibilityChangedAction:property"}}columnsVisibilityChangedAction{{/crossLink}}
       *
       * @method actions.hideAllColumns
       * @returns {undefined}
       */
      hideAllColumns: function hideAllColumns() {
        A(get(this, 'processedColumns').filterBy('mayBeHidden')).setEach('isHidden', true);
        this._sendColumnsVisibilityChangedAction();
      },


      /**
       * Restore columns visibility values according to their default visibility settings (see {{#crossLink "Utils.ModelsTableColumn/defaultVisible:property"}}defaultVisible{{/crossLink}})
       *
       * May trigger sending {{#crossLink "Components.ModelsTable/columnsVisibilityChangedAction:property"}}columnsVisibilityChangedAction{{/crossLink}}
       *
       * @method actions.restoreDefaultVisibility
       * @returns {undefined}
       */
      restoreDefaultVisibility: function restoreDefaultVisibility() {
        var _this5 = this;

        get(this, 'processedColumns').forEach(function (c) {
          set(c, 'isHidden', !get(c, 'defaultVisible'));
          _this5._sendColumnsVisibilityChangedAction();
        });
      },


      /**
       * Toggle visibility for every column in the selected columns set
       *
       * It ignore columns that can't be hidden (see {{#crossLink "Utils.ModelsTableColumn/mayBeHidden:property"}}mayBeHidden{{/crossLink}}). May trigger sending {{#crossLink "Components.ModelsTable/columnsVisibilityChangedAction:property"}}columnsVisibilityChangedAction{{/crossLink}}
       *
       * @method actions.toggleColumnSet
       * @returns {undefined}
       */
      toggleColumnSet: function toggleColumnSet() {
        var _this6 = this;

        var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
            _ref$showColumns = _ref.showColumns,
            showColumns = _ref$showColumns === undefined ? [] : _ref$showColumns,
            hideOtherColumns = _ref.hideOtherColumns,
            _ref$toggleSet = _ref.toggleSet,
            toggleSet = _ref$toggleSet === undefined ? false : _ref$toggleSet;

        var columns = get(this, 'processedColumns');

        // If hideOtherColumns is not set, default to true if toggleSet=false, else to false
        hideOtherColumns = isNone(hideOtherColumns) ? !toggleSet : hideOtherColumns;

        // If showColumns is a function, call it
        if (typeOf(showColumns) === 'function') {
          return run(this, showColumns, columns);
        }

        var setColumns = A([]);
        var otherColumns = A([]);

        columns.forEach(function (column) {
          var columnId = get(column, 'propertyName');

          if (!columnId || !get(column, 'mayBeHidden')) {
            return;
          }

          showColumns = A(showColumns);
          if (showColumns.includes(columnId)) {
            setColumns.pushObject(column);
          } else {
            otherColumns.pushObject(column);
          }
        });

        // By default, all columns should always be set to visible
        // However, if `toggleSet=true`, then the set should be toggled between visible/hidden
        // In this case, if one of the set columns is hidden, make them all visible, else hide them
        var targetVisibility = true;
        if (toggleSet) {
          targetVisibility = !!setColumns.findBy('isVisible', false);
        }

        setColumns.forEach(function (column) {
          var columnId = get(column, 'propertyName');
          if (showColumns.includes(columnId) && get(column, 'isVisible') !== targetVisibility) {
            _this6.send('toggleHidden', column);
          }
        });

        if (hideOtherColumns) {
          otherColumns.forEach(function (column) {
            var columnId = get(column, 'propertyName');

            if (!showColumns.includes(columnId) && get(column, 'isVisible')) {
              _this6.send('toggleHidden', column);
            }
          });
        }
      },


      /**
       * Pagination click-handler
       *
       * It moves user to the selected page. Check [models-table/pagination-numeric](Components.ModelsTablePaginationNumeric.html) and [models-table/pagination-simple](Components.ModelsTablePaginationSimple.html) for usage examples. May trigger sending {{#crossLink "Components.ModelsTable/displayDataChangedAction:property"}}displayDataChangedAction{{/crossLink}}
       *
       * @param {number} pageNumber
       * @method actions.gotoCustomPage
       * @returns {undefined}
       */
      gotoCustomPage: function gotoCustomPage(pageNumber) {
        set(this, 'currentPageNumber', pageNumber);
        this.userInteractionObserver();
      },


      /**
       * Sort selected column by {{#crossLink "Utils.ModelsTableColumn/sortedBy:property"}}sortedBy{{/crossLink}} or {{#crossLink "Utils.ModelsTableColumn/propertyName:property"}}propertyName{{/crossLink}}
       *
       * It will drop sorting for other columns if {{#crossLink "Components.ModelsTable/multipleColumnsSorting:property"}}multipleColumnsSorting{{/crossLink}} is set to `false`. It will add new sorting if {{#crossLink "Components.ModelsTable/multipleColumnsSorting:property"}}multipleColumnsSorting{{/crossLink}} is set to `true`. May trigger sending {{#crossLink "Components.ModelsTable/displayDataChangedAction:property"}}displayDataChangedAction{{/crossLink}}. Table will be dropped to the first page if sorting is done
       *
       * For multiColumns-sorting calling sort will change sort-order. E.g.:
       *
       * ```js
       * sortProperties = ['a:asc', 'b:asc', 'c:desc'];
       * sort({propertyName: 'b'}); // sortProperties now is ['a:asc', 'c:desc', 'b:desc']
       * ```
       *
       * @method actions.sort
       * @param {ModelsTableColumn} column
       * @returns {undefined}
       */
      sort: function sort(column) {
        var sortMap = {
          none: 'asc',
          asc: 'desc',
          desc: 'none'
        };
        var sortedBy = get(column, 'sortedBy') || get(column, 'propertyName');
        if (!sortedBy) {
          return;
        }
        var currentSorting = get(column, 'sorting') || 'none';
        var newSorting = sortMap[currentSorting.toLowerCase()];
        if (sortedBy === get(this, 'currentGroupingPropertyName')) {
          var sortByGroupedFieldDirection = get(this, 'sortByGroupedFieldDirection');
          newSorting = sortByGroupedFieldDirection === 'asc' ? 'desc' : 'asc';
          set(this, 'sortByGroupedFieldDirection', newSorting);
          return;
        }
        var sortingArgs = [column, sortedBy, newSorting];
        if (get(this, 'multipleColumnsSorting')) {
          this._multiColumnsSorting.apply(this, sortingArgs);
        } else {
          this._singleColumnSorting.apply(this, sortingArgs);
        }
        set(this, 'currentPageNumber', 1);
        this.userInteractionObserver();
      },


      /**
       * Expand selected row
       *
       * It will cause expandedRowComponent to be used for it. It will collapse already expanded row if {{#crossLink "Components.ModelsTable/multipleExpand:property"}}multipleExpand{{/crossLink}} is set to `false`. Expanding is assigned to the record itself and not their index. So, if page #1 has first row expanded and user is moved to any another page, first row on new page won't be expanded. But when user will be back to the first page, first row will be expanded. May trigger sending {{#crossLink "Components.ModelsTable/displayDataChangedAction:property"}}displayDataChangedAction{{/crossLink}}
       *
       * @param {number} index
       * @param {object} dataItem
       * @returns {undefined}
       * @method actions.expandRow
       */
      expandRow: function expandRow(index, dataItem) {
        (true && !(typeOf(index) === 'number') && Ember.assert('row index should be numeric', typeOf(index) === 'number'));

        var multipleExpand = get(this, 'multipleExpand');
        var expandedItems = get(this, '_expandedItems');
        if (multipleExpand) {
          expandedItems.pushObject(dataItem);
        } else {
          if (expandedItems.length === 1) {
            expandedItems.clear();
          }
          expandedItems.pushObject(dataItem);
        }
        set(this, '_expandedItems', expandedItems);
        this.userInteractionObserver();
      },


      /**
       * Collapse selected row
       *
       * May trigger sending {{#crossLink "Components.ModelsTable/displayDataChangedAction:property"}}displayDataChangedAction{{/crossLink}}
       *
       * @param {number} index
       * @param {object} dataItem
       * @returns {undefined}
       * @method actions.collapseRow
       */
      collapseRow: function collapseRow(index, dataItem) {
        (true && !(typeOf(index) === 'number') && Ember.assert('row index should be numeric', typeOf(index) === 'number'));

        var expandedItems = get(this, '_expandedItems').without(dataItem);
        set(this, '_expandedItems', expandedItems);
        this.userInteractionObserver();
      },


      /**
       * Expand all rows in the current page
       *
       * It works only if {{#crossLink "Components.ModelsTable/multipleExpand:property"}}multipleExpand{{/crossLink}} is set to `true`. May trigger sending {{#crossLink "Components.ModelsTable/displayDataChangedAction:property"}}displayDataChangedAction{{/crossLink}}
       *
       * @method actions.expandAllRows
       * @returns {undefined}
       */
      expandAllRows: function expandAllRows() {
        var multipleExpand = get(this, 'multipleExpand');
        var visibleContent = get(this, 'visibleContent');
        if (multipleExpand) {
          if (get(this, 'useDataGrouping')) {
            set(this, '_expandedItems', A(objToArray(get(this, 'groupedVisibleContent'))));
          } else {
            set(this, '_expandedItems', A(visibleContent.slice()));
          }
          this.userInteractionObserver();
        }
      },


      /**
       * Collapse all rows in the current page
       *
       * May trigger sending {{#crossLink "Components.ModelsTable/displayDataChangedAction:property"}}displayDataChangedAction{{/crossLink}}
       *
       * @method actions.collapseAllRows
       * @returns {undefined}
       */
      collapseAllRows: function collapseAllRows() {
        set(this, '_expandedItems', A());
        this.userInteractionObserver();
      },


      /**
       * Handler for row-click
       *
       * Toggle <code>selected</code>-state for row. Select only one or multiple rows depends on {{#crossLink "Components.ModelsTable/multipleSelect:property"}}multipleSelect{{/crossLink}} value. May trigger sending {{#crossLink "Components.ModelsTable/displayDataChangedAction:property"}}displayDataChangedAction{{/crossLink}}
       *
       * @param {number} index
       * @param {object} dataItem
       * @returns {undefined}
       * @method actions.clickOnRow
       */
      clickOnRow: function clickOnRow(index, dataItem) {
        (true && !(typeOf(index) === 'number') && Ember.assert('row index should be numeric', typeOf(index) === 'number'));

        if (get(this, 'selectRowOnClick')) {
          var multipleSelect = get(this, 'multipleSelect');
          var selectedItems = get(this, '_selectedItems');
          if (selectedItems.includes(dataItem)) {
            selectedItems = selectedItems.without(dataItem);
            set(this, '_selectedItems', selectedItems);
          } else {
            if (multipleSelect) {
              get(this, '_selectedItems').pushObject(dataItem);
            } else {
              if (selectedItems.length === 1) {
                get(this, '_selectedItems').clear();
              }
              get(this, '_selectedItems').pushObject(dataItem);
            }
          }
        }
        this.userInteractionObserver();
      },


      /**
       *
       * @param {number} index
       * @param {object} dataItem
       * @returns {undefined}
       * @method actions.doubleClickOnRow
       */
      doubleClickOnRow: function doubleClickOnRow(index, dataItem) {
        (true && !(typeOf(index) === 'number') && Ember.assert('row index should be numeric', typeOf(index) === 'number'));

        if (get(this, 'sendRowDoubleClick')) {
          this.sendAction('rowDoubleClickAction', index, dataItem);
        }
      },


      /**
       *
       * @param {number} index
       * @param {object} dataItem
       * @returns {undefined}
       * @method actions.hoverOnRow
       */
      hoverOnRow: function hoverOnRow(index, dataItem) {
        (true && !(typeOf(index) === 'number') && Ember.assert('row index should be numeric', typeOf(index) === 'number'));

        if (get(this, 'sendRowHover')) {
          this.sendAction('rowHoverAction', index, dataItem);
        }
      },


      /**
       *
       * @param {number} index
       * @param {object} dataItem
       * @returns {undefined}
       * @method actions.outRow
       */
      outRow: function outRow(index, dataItem) {
        (true && !(typeOf(index) === 'number') && Ember.assert('row index should be numeric', typeOf(index) === 'number'));

        if (get(this, 'sendRowHover')) {
          this.sendAction('rowOutAction', index, dataItem);
        }
      },


      /**
       * Clear all column filters and global filter
       *
       * May trigger sending {{#crossLink "Components.ModelsTable/displayDataChangedAction:property"}}displayDataChangedAction{{/crossLink}}
       *
       * @returns {undefined}
       * @method actions.clearFilters
       */
      clearFilters: function clearFilters() {
        this._clearFilters();
      },


      /**
       * Select/deselect all rows
       *
       * May trigger sending {{#crossLink "Components.ModelsTable/displayDataChangedAction:property"}}displayDataChangedAction{{/crossLink}}
       *
       * @method actions.toggleAllSelection
       * @returns {undefined}
       */
      toggleAllSelection: function toggleAllSelection() {
        var selectedItems = get(this, '_selectedItems');
        var data = get(this, 'data');
        if (selectedItems.length === data.get('length')) {
          get(this, '_selectedItems').clear();
        } else {
          set(this, '_selectedItems', A(data.slice()));
        }
        this.userInteractionObserver();
      },


      /**
       * Expand or collapse all rows in the rows group
       *
       * **IMPORTANT** `multipleExpand` should be set to `true` otherwise this action won't do anything
       *
       * @method actions.toggleGroupedRowsExpands
       * @param {*} groupedValue
       * @returns {undefined}
       */
      toggleGroupedRowsExpands: function toggleGroupedRowsExpands(groupedValue) {
        if (!get(this, 'multipleExpand')) {
          return;
        }
        var expandedItems = get(this, '_expandedItems');
        var currentGroupingPropertyName = get(this, 'currentGroupingPropertyName');
        var groupedItems = get(this, 'groupedArrangedContent').filterBy(currentGroupingPropertyName, groupedValue);
        var notExpandedGroupItems = groupedItems.filter(function (record) {
          return expandedItems.indexOf(record) === -1;
        });
        if (notExpandedGroupItems.length) {
          var toPush = notExpandedGroupItems.filter(function (record) {
            return expandedItems.indexOf(record) === -1;
          });
          get(this, '_expandedItems').pushObjects(toPush);
        } else {
          groupedItems.forEach(function (record) {
            return expandedItems = expandedItems.without(record);
          });
          set(this, '_expandedItems', expandedItems);
        }
        this.userInteractionObserver();
      },


      /**
       * Select/deselect rows from the rows group
       *
       * **IMPORTANT** `multipleSelect` should be set to `true` otherwise this action won't do anything
       *
       * May trigger sending {{#crossLink "Components.ModelsTable/displayDataChangedAction:property"}}displayDataChangedAction{{/crossLink}}
       *
       * @method actions.toggleGroupedRowsSelection
       * @param {*} groupedValue
       * @returns {undefined}
       */
      toggleGroupedRowsSelection: function toggleGroupedRowsSelection(groupedValue) {
        if (!get(this, 'multipleSelect')) {
          return;
        }
        var selectedItems = get(this, '_selectedItems');
        var currentGroupingPropertyName = get(this, 'currentGroupingPropertyName');
        var groupedItems = get(this, 'groupedArrangedContent').filterBy(currentGroupingPropertyName, groupedValue);
        var notSelectedGroupItems = groupedItems.filter(function (record) {
          return selectedItems.indexOf(record) === -1;
        });
        if (notSelectedGroupItems.length) {
          var toPush = notSelectedGroupItems.filter(function (record) {
            return selectedItems.indexOf(record) === -1;
          });
          get(this, '_selectedItems').pushObjects(toPush);
        } else {
          groupedItems.forEach(function (record) {
            return selectedItems = selectedItems.without(record);
          });
          set(this, '_selectedItems', selectedItems);
        }
        this.userInteractionObserver();
      },


      /**
       * Collapse or expand rows group
       *
       * @method actions.toggleGroupedRows
       * @param {*} groupedValue
       * @returns {undefined}
       */
      toggleGroupedRows: function toggleGroupedRows(groupedValue) {
        var collapsedGroupValues = get(this, '_collapsedGroupValues');
        if (collapsedGroupValues.includes(groupedValue)) {
          collapsedGroupValues = collapsedGroupValues.without(groupedValue);
          set(this, '_collapsedGroupValues', collapsedGroupValues);
        } else {
          get(this, '_collapsedGroupValues').pushObject(groupedValue);
        }
      }
    }

  });
});