define('ember-changeset/utils/computed/inflate', ['exports', 'ember-changeset/utils/is-object', 'ember-deep-set'], function (exports, _isObject, _emberDeepSet) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = inflate;
    var keys = Object.keys;

    /**
     * Inflate an Object, optionally transforming each key's value by
     * `transform` function.
     */
    function inflate(dependentKey) {
        var transform = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (a) {
            return a;
        };

        return Ember.computed(dependentKey, function () {
            var obj = Ember.get(this, dependentKey);
            Ember.runInDebug(function () {
                keys(obj).forEach(function (key) {
                    key.split('.').forEach(function (_part, i, allParts) {
                        if (i < allParts.length - 1) {
                            var path = allParts.slice(0, i + 1).join('.');
                            var msg = 'Path ' + path + ' leading up to ' + key + ' must be an Object if specified.';
                            (true && !((0, _isObject.default)(obj[path]) || Ember.isBlank(obj[path])) && Ember.assert(msg, (0, _isObject.default)(obj[path]) || Ember.isBlank(obj[path])));
                        }
                    });
                });
            });
            var result = keys(obj).sort().reduce(function (inflatedObj, key) {
                (0, _emberDeepSet.default)(inflatedObj, key, transform(obj[key]));
                return inflatedObj;
            }, {});
            return result;
        }).readOnly();
    }
});