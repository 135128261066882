define('ember-models-table/mixins/hover-support', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    enter: function enter() {},
    leave: function leave() {},
    mouseEnter: function mouseEnter() {
      this.enter();
    },
    focusIn: function focusIn() {
      this.enter();
    },
    mouseLeave: function mouseLeave() {
      this.leave();
    },
    focusOut: function focusOut() {
      this.leave();
    }
  });
});