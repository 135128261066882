define('ember-intl/adapters/default', ['exports', 'ember-intl/models/translation'], function (exports, _translation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var get = Ember.get;
  var computed = Ember.computed;
  var emberArray = Ember.A;
  var getOwner = Ember.getOwner;


  var DefaultTranslationAdapter = EmberObject.extend({
    _seen: null,

    /** @private **/
    locales: computed('_seen.[]', function () {
      return get(this, '_seen').map(function (l) {
        return l.localeName;
      });
    }).readOnly(),

    init: function init() {
      this._super();
      this._seen = emberArray();
    },
    lookupLocale: function lookupLocale(localeName) {
      return this._seen.findBy('localeName', localeName);
    },
    localeFactory: function localeFactory(localeName) {
      var owner = getOwner(this);
      var lookupName = 'ember-intl@translation:' + localeName;
      var model = owner.lookup(lookupName);

      if (model) {
        return model;
      }

      var Klass = void 0;
      if (owner.hasRegistration('model:ember-intl-translation')) {
        Klass = owner.factoryFor('model:ember-intl-translation').class;
      } else {
        Klass = _translation.default;
      }

      var ModelKlass = Klass.extend();
      Object.defineProperty(ModelKlass.proto(), 'localeName', {
        writable: false,
        enumerable: true,
        value: localeName
      });

      owner.register(lookupName, ModelKlass);
      model = owner.lookup(lookupName);
      this._seen.pushObject(model);

      return model;
    },
    has: function has(localeName, translationKey) {
      var model = this.lookupLocale(localeName);

      return model && model.has(translationKey);
    },
    lookup: function lookup(localeNames, translationKey) {
      for (var i = 0; i < localeNames.length; i++) {
        var localeName = localeNames[i];
        var model = this.lookupLocale(localeName);

        if (model && model.has(translationKey)) {
          return model.getValue(translationKey);
        }
      }
    },
    translationsFor: function translationsFor(localeName) {
      return this.localeFactory(localeName);
    },
    findTranslationByKey: function findTranslationByKey(localeNames, translationKey) {
      return this.lookup(localeNames, translationKey);
    }
  });

  exports.default = DefaultTranslationAdapter;
});