define('ember-cli-string-helpers/helpers/classify', ['exports', 'ember-cli-string-helpers/-private/create-string-helper'], function (exports, _createStringHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.classify = undefined;
  var helper = Ember.Helper.helper;
  var _classify = Ember.String.classify;
  var classify = exports.classify = (0, _createStringHelper.default)(_classify);
  exports.default = helper(classify);
});