define('ember-intl/helpers/format-relative', ['exports', 'ember-intl/helpers/-format-base'], function (exports, _formatBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var bind = Ember.run.bind;


  var runBind = bind;

  exports.default = _formatBase.default.extend({
    format: function format(params, hash) {
      return this.intl.formatRelative(params, hash);
    },
    compute: function compute(params, hash) {
      this.clearTimer();

      if (hash && typeof hash.interval !== 'undefined') {
        /* setTimeout versus Ember.run.later so tests will not wait infinitely */
        this.timer = setTimeout(runBind(this, this.recompute), parseInt(hash.interval, 10));
      }

      return this._super(params, hash);
    },
    clearTimer: function clearTimer() {
      clearTimeout(this.timer);
    },
    willDestroy: function willDestroy() {
      this._super();

      this.clearTimer();
    }
  });
});