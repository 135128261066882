define('ember-cli-string-helpers/helpers/html-safe', ['exports', 'ember-cli-string-helpers/-private/create-string-helper'], function (exports, _createStringHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.htmlSafe = undefined;
  var helper = Ember.Helper.helper;
  var _htmlSafe = Ember.String.htmlSafe;
  var htmlSafe = exports.htmlSafe = (0, _createStringHelper.default)(_htmlSafe);
  exports.default = helper(htmlSafe);
});