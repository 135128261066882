define('ember-cli-string-helpers/helpers/camelize', ['exports', 'ember-cli-string-helpers/-private/create-string-helper'], function (exports, _createStringHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.camelize = undefined;
  var helper = Ember.Helper.helper;
  var _camelize = Ember.String.camelize;
  var camelize = exports.camelize = (0, _createStringHelper.default)(_camelize);
  exports.default = helper(camelize);
});