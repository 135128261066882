define('ember-intl/formatters/format-date', ['exports', 'intl-format-cache', 'ember-intl/formatters/-base'], function (exports, _intlFormatCache, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  function assertIsDate(date, errMsg) {
    (true && !(isFinite(date)) && Ember.assert(errMsg, isFinite(date)));
  }

  var FormatDate = _base.default.extend({
    formatter: computed({
      get: function get() {
        return (0, _intlFormatCache.default)(Intl.DateTimeFormat);
      }
    }).readOnly(),

    format: function format(value, options, ctx) {
      var dateTime = new Date(value);
      assertIsDate(dateTime, 'A date or timestamp must be provided to format-date');

      var formatOptions = this.filterSupporedOptions(options);

      return this._format(dateTime, formatOptions, undefined, ctx);
    }
  });

  FormatDate.reopenClass({
    formatType: 'date',
    supportedOptions: ['localeMatcher', 'timeZone', 'hour12', 'formatMatcher', 'weekday', 'era', 'year', 'month', 'day', 'hour', 'minute', 'second', 'timeZoneName']
  });

  exports.default = FormatDate;
});