define('ember-rapid-forms/components/html-checkbox', ['exports', 'ember', 'ember-rapid-forms/templates/components/html-checkbox'], function (exports, _ember, _emberRapidFormsTemplatesComponentsHtmlCheckbox) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberRapidFormsTemplatesComponentsHtmlCheckbox['default'],
    tagName: 'input',
    attributeBindings: ['type', 'checked'],

    type: "checkbox",
    checked: false,
    didReceiveAttrs: function didReceiveAttrs() /*attrs*/{
      this._super.apply(this, arguments);
      // set it to the correct value of the selection
      this.checked = _ember['default'].computed('mainComponent.model.' + this.get('mainComponent.property'), function () {
        return this.get('mainComponent.model.' + this.get('mainComponent.property'));
      });
    },
    change: function change() {
      var selectedEl = this.$()[0];
      var checked = selectedEl.checked;
      this.set('mainComponent.model.' + this.get('mainComponent.property'), checked);
      var changeAction = this.get('action');
      if (changeAction) {
        changeAction(checked);
      } else {
        // TODO make deprecate here so everyone switches to new action syntax
      }
    },
    input: function input() {
      // input is always called when input is altert
      // except in IE9 where when cutting or removing things it doesn't get fired
      // https://developer.mozilla.org/en-US/docs/Web/Events/input#Browser_compatibility
      var selectedEl = this.$()[0];
      var checked = selectedEl.checked;
      this.set('mainComponent.model.' + this.get('mainComponent.property'), checked);
      var changeAction = this.get('action');
      if (changeAction) {
        changeAction(checked);
      } else {
        // TODO make deprecate here so everyone switches to new action syntax
      }
    }

  });
});