define('ember-intl/utils/is-equal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (a, b) {
    if (!isArray(a) || !isArray(b)) {
      return false;
    }
    if (a === b) {
      return true;
    }

    return a.toString() === b.toString();
  };

  var isArray = Ember.isArray;
});