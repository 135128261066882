define('ember-bootstrap-datepicker/components/bootstrap-datepicker', ['exports', 'ember-bootstrap-datepicker/components/datepicker-support'], function (exports, _datepickerSupport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty;
  var Component = Ember.Component;
  var $ = Ember.$;
  exports.default = Component.extend(_datepickerSupport.default, {
    instrumentDisplay: '{{input type="text"}}',

    classNames: ['ember-text-field'],

    tagName: 'input',

    attributeBindings: ['accesskey', 'autocomplete', 'autofocus', 'contenteditable', 'contextmenu', 'dir', 'disabled', 'draggable', 'dropzone', 'form', 'hidden', 'id', 'lang', 'list', 'max', 'min', 'name', 'placeholder', 'readonly', 'required', 'spellcheck', 'step', 'tabindex', 'title', 'translate', 'type'],

    type: 'text',

    forceParse: true,

    _forceParse: function _forceParse() {

      if (!this.get('forceParse')) {
        return;
      }

      var date = null,
          format = this.get('format');

      if (!isEmpty(this.element.value) && !isEmpty(format)) {
        var dpg = $.fn.datepicker.DPGlobal;
        date = dpg.parseDate(this.element.value, dpg.parseFormat(format));
      } else {
        date = Date.parse(this.element.value);
      }

      if (!isNaN(date)) {
        this.set('value', new Date(date));
      }
    }
  });
});