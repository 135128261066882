define('ember-cli-string-helpers/helpers/dasherize', ['exports', 'ember-cli-string-helpers/-private/create-string-helper'], function (exports, _createStringHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dasherize = undefined;
  var helper = Ember.Helper.helper;
  var _dasherize = Ember.String.dasherize;
  var dasherize = exports.dasherize = (0, _createStringHelper.default)(_dasherize);
  exports.default = helper(dasherize);
});