define('ember-power-select-blockless/components/power-select-blockless', ['exports', 'ember-power-select-blockless/templates/components/power-select-blockless'], function (exports, _powerSelectBlockless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    layout: _powerSelectBlockless.default,

    // CPs
    searchField: computed('labelPath', function () {
      return this.get('labelPath');
    })
  });
});