define("ember-responsive/null-match-media", ["exports"], function (exports) {
  /**
   * Stub function that is `matchMedia` API compatible but always returns
   * `false`. Useful for server-side environments like FastBoot where there
   * is no viewport.
   */

  exports["default"] = function () {
    return {
      matches: false
    };
  };
});