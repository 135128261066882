define('ember-useragent/services/user-agent', ['exports', 'ua-parser-js'], function (exports, _uaParserJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var computed = Ember.computed;
  var get = Ember.get;
  var observer = Ember.observer;
  var setProperties = Ember.setProperties;
  var readOnly = Ember.computed.readOnly;
  var assign = Ember.assign;
  var isEqual = Ember.isEqual;
  var getOwner = Ember.getOwner;
  exports.default = Service.extend({

    fastboot: computed(function () {
      return getOwner(this).lookup('service:fastboot');
    }),

    isFastBoot: readOnly('fastboot.isFastBoot'),

    userAgent: computed(function () {
      if (get(this, 'isFastBoot')) {
        var headers = get(this, 'fastboot.request.headers');
        var userAgent = headers.get('user-agent');

        (true && !(userAgent) && Ember.assert('No userAgent present in ember-useragent/services/user-agent (FastBoot)', userAgent));

        return userAgent;
      } else {
        if (window && window.navigator) {
          var _userAgent = window.navigator.userAgent;

          (true && !(_userAgent) && Ember.assert('No userAgent present in ember-useragent/services/user-agent (Browser)', _userAgent));

          return _userAgent;
        }
      }
    }),

    UAParser: computed('userAgent', function () {
      var userAgent = get(this, 'userAgent');

      if (get(this, 'isFastBoot')) {
        var _UAParser = FastBoot.require('ua-parser-js');
        return new _UAParser(userAgent);
      }

      return new _uaParserJs.default(userAgent);
    }),

    setupService: observer('UAParser', function () {
      var parser = get(this, 'UAParser');

      var browser = parser.getBrowser();
      var device = parser.getDevice();
      var engine = parser.getEngine();
      var os = parser.getOS();

      setProperties(this, assign({

        browser: {
          info: browser,
          isChrome: isEqual(browser.name, 'Chrome'),
          isChromeHeadless: isEqual(browser.name, 'Chrome Headless'),
          isEdge: isEqual(browser.name, 'Edge'),
          isFirefox: isEqual(browser.name, 'Firefox'),
          isIE: isEqual(browser.name, 'IE') || isEqual(browser.name, 'IE Mobile'),
          isSafari: isEqual(browser.name, 'Safari') || isEqual(browser.name, 'Mobile Safari')
        },

        device: {
          info: device,
          isConsole: isEqual(device.type, 'console'),
          isDesktop: !device.type,
          isMobile: isEqual(device.type, 'mobile'),
          isTablet: isEqual(device.type, 'tablet')
        },

        engine: {
          info: engine,
          isWebkit: isEqual(engine.name, 'WebKit')
        },

        os: {
          info: os,
          isAndroid: isEqual(os.name, 'Android'),
          isIOS: isEqual(os.name, 'iOS'),
          isLinux: ['CentOS', 'Fedora', 'Linpus', 'Linux', 'MeeGo', 'PCLinuxOS', 'RedHat', 'SUSE', 'Ubuntu', 'VectorLinux'].indexOf(os.name) > -1,
          isMacOS: isEqual(os.name, 'Mac OS'),
          isWindows: ['Windows', 'Windows Phone', 'Windows Mobile'].indexOf(os.name) > -1
        }

      }, parser));
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.setupService();
    }
  });
});