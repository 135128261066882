define('ember-power-select-with-create/components/power-select-with-create', ['exports', 'ember-power-select-with-create/templates/components/power-select-with-create', 'ember-power-select/utils/group-utils'], function (exports, _powerSelectWithCreate, _groupUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get,
      RSVP = Ember.RSVP;
  exports.default = Ember.Component.extend({
    tagName: '',
    layout: _powerSelectWithCreate.default,
    matcher: _groupUtils.defaultMatcher,
    suggestedOptionComponent: 'power-select-with-create/suggested-option',
    powerSelectComponentName: 'power-select',

    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);
      Ember.assert('{{power-select-with-create}} requires an `oncreate` function', this.get('oncreate') && typeof this.get('oncreate') === 'function');
    },


    // CPs
    optionsArray: computed('options.[]', function () {
      var options = this.get('options');
      if (!options) {
        return Ember.A();
      }
      if (options.then) {
        return options.then(function (value) {
          return Ember.A(value).toArray();
        });
      } else {
        return Ember.A(options).toArray();
      }
    }),

    shouldShowCreateOption: function shouldShowCreateOption(term, options) {
      return this.get('showCreateWhen') ? this.get('showCreateWhen')(term, options) : true;
    },
    addCreateOption: function addCreateOption(term, results) {
      if (this.shouldShowCreateOption(term, results)) {
        if (this.get('showCreatePosition') === 'bottom') {
          results.push(this.buildSuggestionForTerm(term));
        } else {
          results.unshift(this.buildSuggestionForTerm(term));
        }
      }
    },
    searchAndSuggest: function searchAndSuggest(term, select) {
      var _this = this;

      return RSVP.resolve(this.get('optionsArray')).then(function (newOptions) {

        if (term.length === 0) {
          return newOptions;
        }

        var searchAction = _this.get('search');
        if (searchAction) {
          return Ember.RSVP.resolve(searchAction(term, select)).then(function (results) {
            if (results.toArray) {
              results = results.toArray();
            }
            _this.addCreateOption(term, results);
            return results;
          });
        }

        newOptions = _this.filter(Ember.A(newOptions), term);
        _this.addCreateOption(term, newOptions);

        return newOptions;
      });
    },
    selectOrCreate: function selectOrCreate(selection, select) {
      if (selection && selection.__isSuggestion__) {
        this.get('oncreate')(selection.__value__, select);
      } else {
        this.get('onchange')(selection, select);
      }
    },


    // Methods
    filter: function filter(options, searchText) {
      var _this2 = this;

      var matcher = void 0;
      if (this.get('searchField')) {
        matcher = function matcher(option, text) {
          return _this2.matcher(get(option, _this2.get('searchField')), text);
        };
      } else {
        matcher = function matcher(option, text) {
          return _this2.matcher(option, text);
        };
      }
      return (0, _groupUtils.filterOptions)(options || [], searchText, matcher);
    },
    buildSuggestionForTerm: function buildSuggestionForTerm(term) {
      return {
        __isSuggestion__: true,
        __value__: term,
        text: this.buildSuggestionLabel(term)
      };
    },
    buildSuggestionLabel: function buildSuggestionLabel(term) {
      var buildSuggestion = this.get('buildSuggestion');
      if (buildSuggestion) {
        return buildSuggestion(term);
      }
      return 'Add "' + term + '"...';
    }
  });
});