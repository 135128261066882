define('ember-models-table/helpers/and', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.and = and;
  var helper = Ember.Helper.helper;
  function and(params /*, hash*/) {
    for (var i = 0, len = params.length; i < len; i++) {
      if (!params[i]) {
        return false;
      }
    }
    return true;
  }

  exports.default = helper(and);
});